import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NatSpecColor} from '../models/colors.model';

@Injectable()
export class ColorsService {
  constructor(private _api: ApiService,
              private _http: HttpClient) {
  }

  public getColors(): Observable<any> {
    return this._api.get('/productColors');
  }
  public updateColor(body: NatSpecColor, colorId: number): Observable<any> {
    return this._api.put('/productColors/' + colorId, body);
  }
  public newColor(body: any): Observable<any> {
    return this._api.post('/productColors', body);
  }

}
