import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-help',
  templateUrl: './sms-help.component.html',
  styleUrls: ['./sms-help.component.css']
})
export class SmsHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
