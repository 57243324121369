import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-query-modal',
  template: `
    <h2 mat-dialog-title>{{ prompt }}</h2>
    <mat-dialog-content>
      <mat-form-field>
        <input matInput #queryNameInput placeholder="{{ prompt }}">
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button color="primary" (click)="onSubmit(queryNameInput.value)">Save</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./query-modal.component.css']
})
export class QueryModalComponent implements OnInit {

  @Input() prompt?: string;
  constructor(
    private dialogRef: MatDialogRef<QueryModalComponent>
  ) { }

  onSubmit(queryName: string) {
    this.dialogRef.close(queryName);
  }
  ngOnInit(): void {
  }

}
