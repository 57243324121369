import {Component, NgZone, OnInit} from '@angular/core';
import {OrderList} from '../models/orders.model';
import {UserState} from '../store/reducers/user.reducer';
import {Router} from '@angular/router';
import {OrdersService} from '../services/orders.service';
import {Store} from '@ngrx/store';
import {getCurrentUser} from '../store/reducers';
import * as moment from 'moment';
import {FormList} from '../models/forms.model';
import {FormsService} from '../services/forms.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  forms: FormList[];
  noData = true;
  currentUser: UserState = {
    user: {myId: -1, email: '', first: '', last: '' },
    authenticated: false,
    token: ''};

  public pageTitle = 'Forms Management';

  constructor(private router: Router,
              private service: OrdersService,
              private formsService: FormsService,
              private store: Store<UserState>,
              private ngZone: NgZone) { }

  ngOnInit() {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        serverSide: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.ngZone.run(() => {
            this.formsService.getFormsTable(
              dataTablesParameters, this.currentUser.user.committeeId)
              .subscribe(resp => {
                if (resp.hasOwnProperty('formList')) {
                  this.forms = resp.formList;
                  if (this.forms.length > 0) {
                    this.noData = false;
                  } else {
                    this.noData = true;
                  }
                } else {
                  resp['formList'] = [];
                }

                callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.formList
                });
              });
          });
        },
        order: [[1, 'desc']],
        columns: [{
          title: 'ID',
          data: 'id'
        }, {
          title: 'Committee Id',
          data: 'committeeId'
        }, {
          title: 'Submit Date',
          data: 'submitDate'
        }],
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          // Unbind first in order to avoid any duplicate handler
          // (see https://github.com/l-lin/angular-datatables/issues/87)
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.someClickHandler(data);
          });
          const datenumeric = data['submitDate'];
          const dateDate = moment(datenumeric);
          $('td:eq(2)', row).text(dateDate.format('LLL'));
          return row;
        }
      };
    });
  }

  someClickHandler(info: any): void {
    /*
    this.selectedDonor = {identity: info.identity};
    const dState: DonorState = {donor: this.selectedDonor, enhanced: info};
    this.store.dispatch(new donorActions.SetSelectedDonorAction(dState));
    this.router.navigateByUrl('/app/donor-details');
    */
    const formId = info.id;
    this.router.navigateByUrl('forms-detail/' + formId);
  }

  logout() {
    this.router.navigateByUrl('');
  }
}
