<div class="container-fluid">
  <div class="row bg-light pt-1 filter-sort-section">
    <div class="col-sm-10 mx-auto d-inline-flex align-items-center">
      <label for="">Filter by:</label>
      <button [ngClass]="{'active' : buttonState.all}" class="btn btn-filter" (click)="FilterAndSetButtonState()">All</button>
      <button [ngClass]="{'active' : buttonState.merch}" class="btn btn-filter" (click)="FilterAndSetButtonState('merch')">Merchandise</button>
      <button [ngClass]="{'active' : buttonState.reserve}" class="btn btn-filter" (click)="FilterAndSetButtonState('reserve')">Reservations</button>
      <div class="input-group search-right">
        <input type="search" class="form-control" name="searchText" (keydown)="eventHandler($event)"
               id="searchText" [(ngModel)]="searchFilterText" value="{{searchFilterText}}" placeholder="Search Products">
      </div>
    </div>
  </div>
  <div class="row bg-light">
    <div class="col-sm-12 mx-auto d-block">
      <p><span class="prod-list-header">Product List</span>
        <span class="prod-list-icons dropdown text-right">
        <label class="sort-label" for="">Sort by:</label>
        <select class="custom-select sort-select" #selectElem (change)="doSort(selectElem.value)">
          <option selected value="namea-z">Name A-Z</option>
          <option value="namez-a">Name Z-A</option>
        </select>
      </span>
      </p>
      <!-- <pre>{{ masterList | json}}</pre> -->
      <div class="grid">
        <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
        <div class="prod-cat-card" *ngFor="let category of productCategories | stringFilter: filter " (click)="gotoDetails(category)">
          <app-cat-card [item]="category"></app-cat-card>
        </div>
      </div>
    </div>
  </div>
</div>
