<div class="row mt-2">
  <div class="col-md-12 order-table">
    <h6>Find Forms Instructions</h6>
    <div>
      <p>The table to the right shows ALL forms in the system that were submitted and assigned to you.</p>
    </div>
    <p>To export all of the forms that you are responsible for, click one of the buttons below</p>
    <button class="btn btn-primary btn-round ml-3" (click)="exportAll()" type="button">Export My Forms</button>
  </div>
</div>
