import {Component, NgZone, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {ApiService} from '../services/api.service';
import {UserState} from '../store/reducers/user.reducer';
import {Store} from '@ngrx/store';
import * as userActions from '../store/actions/user.actions';
import {getCurrentUser} from '../store/reducers';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public pageTitle = 'National Specialty Administration';
  message = '';
  loggedIn = false;
  myClub: string;
  loginFormGroup: FormGroup;
  signInInvalid: boolean;
  signInFailed: boolean;
  loggingIn: boolean;
  authenticated: boolean;
  isAdmin: boolean;

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private ngZone: NgZone,
    private service: ApiService,
    private store: Store<UserState>
  ) {
    this.signInInvalid = false;
    this.signInFailed = false;
    this.loggingIn = false;
  }

  ngOnInit() {
    this.loginFormGroup = this._formBuilder.group({
      emailAddress: [ '', Validators.required],
      password: [ '', Validators.required]
    });
/*
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.ngZone.run(() => {
        if (!state.authenticated) {
          // we don't have a logintoken so check the cookie
          let cookieVal = '';
          const knumDays = 1;
          const value = '; ' + document.cookie;
          const parts = value.split('; natspecadmintoken=');
          if (parts.length === 2) {
            // found a token in a cookie, try using it
            cookieVal = parts.pop().split(';').shift();
            console.log('header constructor found a cookie with a token');
            this.service.setAuthToken(cookieVal);
            const loginGuy: UserState = {user: state.user,
              authenticated: true, token: cookieVal};
            this.store.dispatch( new userActions.LoginUserAction(loginGuy));
            this.router.navigateByUrl('/admin');
          }
        } else {
          this.router.navigateByUrl('/admin');
        }
      });
    });
*/
  }

  doLogin() {
    const em = this.loginFormGroup.controls['emailAddress'].value;
    const pw = this.loginFormGroup.controls['password'].value;
    this.signInInvalid = false;
    this.signInFailed = false;
    if ((em.length > 0) && (pw.length > 0) && (this.loginFormGroup.controls['emailAddress'].valid)) {
      this.loggingIn = true;
      this.service.post('/members/validate', {email: em, password: pw, isAdmin: true}).subscribe((res) => {
        this.ngZone.run(() => {
          this.loggingIn = false;
          if ((res['valid'] === 1) && ((res['committee_chair'] === true) || (res['isAdmin'] === true))) {
            this.loggedIn = true;
            const loginGuy: UserState = {user: res,
              authenticated: true, token: res.token};
            this.store.dispatch( new userActions.LoginUserAction(loginGuy));
            const tokenStr = res.token;
            this.service.setAuthToken(tokenStr);
            const date = new Date();
            const knumDays = 30;
            date.setTime(date.getTime() + (knumDays * 24 * 60 * 60 * 1000));
            const expires = '; expires=' + date.toUTCString();
            document.cookie = 'natspecadmintoken=' + tokenStr + expires + '; path=/';
            this.router.navigateByUrl('admin');
          } else {
            this.signInFailed = true;
            console.log('Login failed: ' + JSON.stringify(res));
          }
        });
      });
    } else {
      this.signInInvalid = true;
    }
  }

}
