import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import {modes} from '../models/modes.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationData} from '../shared/validationMessages.component';
import {NatSpecCommittee} from '../models/committee.model';
import {CommitteesService} from '../services/committees.service';
import {NatSpecColor} from '../models/colors.model';
import {ColorsService} from '../services/colors.service';

@Component({
  selector: 'app-manage-colors',
  templateUrl: './manage-colors.component.html',
  styleUrls: ['./manage-colors.component.css']
})
export class ManageColorsComponent implements OnInit, AfterViewInit {

  colors: NatSpecColor[];
  mySpinner: boolean;
  mode: number;
  selectedColor: NatSpecColor;
  colorForm: FormGroup;

  validationData: ValidationData = {
    'colorName': [
      {type: 'required', message: 'Please enter a name for this color'}
    ]
  };

  constructor(private api: ApiService,
              private service: ColorsService,
              private formBuilder: FormBuilder,
              private ngZone: NgZone) {
    this.colorForm = this.formBuilder.group({
      colorName: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.mode = modes.TABLEVIEW;
    this.selectedColor = null;
  }

  ngAfterViewInit(): void {
    this.refreshColors();
  }

  refreshColors() {
    this.mode = modes.TABLEVIEW;
    setTimeout(() => {
      this.mySpinner = true;
      this.service.getColors().subscribe((res) => {
        this.ngZone.run(() => {
          this.colors = res;
          this.mySpinner = false;
        });
      });
    }, 500);
  }
  addNewColor() {
    this.selectedColor = {id: -1, name: ''};
    const controls = this.colorForm.controls;
    const color = this.selectedColor;
    controls.colorName.setValue(color.name);

    this.mode = modes.EDITVIEW;
  }
  editColor() {
    const controls = this.colorForm.controls;
    const color = this.selectedColor;
    controls.colorName.setValue(color.name);

    this.mode = modes.EDITVIEW;
  }
  saveColor() {
    this.colorForm.updateValueAndValidity();
    if (!this.colorForm.valid) {
      return;
    }
    this.selectedColor.name = this.colorForm.controls.colorName.value;

    if (this.selectedColor.id === -1) {
      this.service.newColor(this.selectedColor)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshColors();
          });
        });
    } else {
      this.service.updateColor(this.selectedColor, this.selectedColor.id)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshColors();
          });
        });
    }
  }

  colorDetails(index) {
    this.selectedColor = this.colors[index];
    this.mode = modes.DETAILVIEW;
  }

  isTableViewMode() {
    return this.mode === modes.TABLEVIEW;
  }
  isDetailViewMode() {
    return this.mode === modes.DETAILVIEW;
  }
  isEditViewMode() {
    return this.mode === modes.EDITVIEW;
  }
}
