import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FormList} from '../models/forms.model';
import {SmsResponse} from '../models/sms.model';

@Injectable()
export class SmsService {
  constructor(private _api: ApiService,
              private _http: HttpClient) {}

  public sendAMessage(topic: string, message: string): Observable<SmsResponse> {
    return this._api.post('/sms/bulk', {messageTopic: topic, bodyMessage: message});
  }
  public sendAMessageAtLaterTime(topic: string, message: string, atTime: string): Observable<SmsResponse> {
    return this._api.post('/sms/bulk',
      {
        messageTopic: topic,
        bodyMessage: message,
        sendAt: atTime
      });
  }
  public getSubscribersTable(body: any, committeeId: number): Observable<any> {
    return this._api.post('/sms/table?comId=' + committeeId, body);
  }

}
