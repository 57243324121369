import { Component, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

export interface ValidationData {
  [name: string]: {
    type: string;
    message: string;
  }[];
}

@Component({
  selector: 'app-validation-messages',
  template: `<mat-error *ngFor="let message of getMessages()">
    <mat-error style="font-size: 75%" >{{message}}</mat-error>
    </mat-error>`
})

export class ValidationMessagesComponent {
  @Input() data?: ValidationData;
  @Input() formGroup?: FormGroup;
  @Input() control?: AbstractControl;
  @Input() name?: string;

  getMessages() {
    const messages: string[] = [];
    let control: AbstractControl;
    if (!this.name || !this.data) {
      return;
    }
    if (this.control) {
      control = this.control;
    } else {
      if (!this.formGroup || !this.formGroup.get(this.name)) {
        return;
      }
      if (this.name) {
        const s = this.name;
        if (this.formGroup) {
          control = this.formGroup.get(s);
        }
      }
    }
    if (control.dirty || control.touched) {
      const data = this.data[this.name];
      if (data) {
        data.forEach(item => {
          if (control.hasError(item.type)) {
            messages.push(item.message);
          }
        });
      }
    }
    return messages;
  }

}
