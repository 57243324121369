<div class="container-fluid">
  <div class="row">
    <div class="col-md-2">
      <ul>
        <li class="mainList" (click)="gotoAdmin()">Home</li>
        <li class="mainList" (click)="gotoOrders()">Order Search</li>
        <li class="mainList" (click)="gotoProducts()">Manage Products</li>
        <li>Shipping Classes</li>
        <li class="mainList" (click)="logout()">Logout</li>
      </ul>
    </div>
    <div class="col-md-10">
      <h2>Manage Shipping Classes</h2>
      <div>
      </div>
    </div>
  </div>
</div>
