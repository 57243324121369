import {Component, NgZone, OnInit} from '@angular/core';
import {OrderItem, SubOrder} from '../models/orders.model';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BsModalService} from 'ngx-bootstrap/modal';
import {OrdersService} from '../services/orders.service';
import {Store} from '@ngrx/store';
import {UserState} from '../store/reducers/user.reducer';

@Component({
  selector: 'app-packing-slip',
  templateUrl: './packing-slip.component.html',
  styleUrls: ['./packing-slip.component.css']
})
export class PackingSlipComponent implements OnInit {

  workingSub: SubOrder;
  private sub: any;
  subOrderId: number;

  constructor(    private router: Router,
                  private route: ActivatedRoute,
                  private ngZone: NgZone,
                  private http: HttpClient,
                  private modalService: BsModalService,
                  private service: OrdersService,
                  private store: Store<UserState>
  ) { }

  ngOnInit() {
    this.sub = this.route.paramMap.subscribe(params => {
      this.ngZone.run(() => {
        this.workingSub = {};
        this.subOrderId = +params.get('subOrderId'); // (+) converts string 'id' to a number
        this.service.getSuborderDetails(this.subOrderId).subscribe((res) => {
          this.ngZone.run(() => {
            this.workingSub = res;
            this.workingSub.orderitems.forEach((aOI) => {
              aOI.showDataPoints = false;
              aOI.parsedDataPoints = JSON.parse(aOI.dataPoints);
              aOI.showDataPoints = aOI.parsedDataPoints.toString() !== '{}';
            });
          });
        });
      });
    });
  }

  getDisplayName(item: OrderItem) {
    let displayName = item.product.name;
    if (item.product_variant !== null) {
      displayName += item.product_variant.displayName;
    }
    return displayName;
  }
}
