import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrdersComponent } from './orders/orders.component';
import { ProductsComponent } from './products/products.component';
import { ShippingComponent } from './shipping/shipping.component';
import { DataTablesModule } from 'angular-datatables';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxPaginationModule} from 'ngx-pagination';
import { CatCardComponent } from './cat-card/cat-card.component';
import {ProductsService} from './services/products.service';
import {StringFilterPipe} from './services/StringFilter';
import {OrdersService} from './services/orders.service';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { ProductCategoryDetailComponent } from './product-category-detail/product-category-detail.component';
import { ProdCardComponent } from './prod-card/prod-card.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { SubordersComponent } from './suborders/suborders.component';
import { SubordersHelpComponent } from './suborders-help/suborders-help.component';
import { OrdersHelpComponent } from './orders-help/orders-help.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { reducers, metaReducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import {UserEffects} from './store/effects/user.effects';
import { ChartsModule } from 'ng2-charts';
import { AdminHelpComponent } from './admin-help/admin-help.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { PackingSlipComponent } from './packing-slip/packing-slip.component';
import {ExcelService} from './services/excel.service';
import {CurrencyPipe} from '@angular/common';
import {TreeModule} from '@circlon/angular-tree-component';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import { SystemAdminComponent } from './system-admin/system-admin.component';
import { ManageEventsComponent } from './manage-events/manage-events.component';
import { ManageCommitteesComponent } from './manage-committees/manage-committees.component';
import { ManageCategoriesComponent } from './manage-categories/manage-categories.component';
import { ManageColorsComponent } from './manage-colors/manage-colors.component';
import { ManageSizesComponent } from './manage-sizes/manage-sizes.component';
import { ManageProductsComponent } from './manage-products/manage-products.component';
import {EventsService} from './services/events.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ValidationMessagesComponent} from './shared/validationMessages.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {CommitteesService} from './services/committees.service';
import {ColorsService} from './services/colors.service';
import {SizesService} from './services/sizes.service';
import {CategoriesService} from './services/categories.service';
import {DateTimeFromMillisecondsPipe, DateTimeToFormatPipe, DateTimeToLocalPipe, LuxonModule} from 'luxon-angular';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import { FormsComponent } from './forms/forms.component';
import { FormsHelpComponent } from './forms-help/forms-help.component';
import { FormsDetailComponent } from './forms-detail/forms-detail.component';
import {FormsService} from './services/forms.service';
import { SmsComponent } from './sms/sms.component';
import {SmsService} from './services/sms.service';
import { SmsHelpComponent } from './sms-help/sms-help.component';
import {
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import { SuborderDetailComponent } from './suborder-detail/suborder-detail.component';
import {MatSelectModule} from '@angular/material/select';
import { FinanceReportsComponent } from './finance-reports/finance-reports.component';
import { FinanceReportsHelpComponent } from './finance-reports-help/finance-reports-help.component';
import { ReportCardComponent } from './report-card/report-card.component';
import {MatDialogModule} from '@angular/material/dialog';
import { QueryModalComponent } from './query-modal/query-modal.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    HomeComponent,
    HeaderComponent,
    OrdersComponent,
    ProductsComponent,
    ShippingComponent,
    StringFilterPipe,
    CatCardComponent,
    OrderDetailComponent,
    ProductCategoryDetailComponent,
    ProdCardComponent,
    ProductDetailComponent,
    SubordersComponent,
    SubordersHelpComponent,
    OrdersHelpComponent,
    AdminHelpComponent,
    PrintLayoutComponent,
    PackingSlipComponent,
    SystemAdminComponent,
    ManageEventsComponent,
    ManageCommitteesComponent,
    ManageCategoriesComponent,
    ManageColorsComponent,
    ManageSizesComponent,
    ManageProductsComponent,
    ValidationMessagesComponent,
    FormsComponent,
    FormsHelpComponent,
    FormsDetailComponent,
    SmsComponent,
    SmsHelpComponent,
    SuborderDetailComponent,
    FinanceReportsComponent,
    FinanceReportsHelpComponent,
    ReportCardComponent,
    QueryModalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ChartsModule,
    DataTablesModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    NgxPaginationModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TreeModule,
    LuxonModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AppEffects, UserEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    TreeModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatRadioModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    CollapseModule.forRoot()
  ],
  providers: [ApiService, ProductsService, OrdersService, BsModalService, ExcelService, FormsService,
  CurrencyPipe, EventsService, CommitteesService, ColorsService, SizesService, CategoriesService, SmsService,
    DateTimeFromMillisecondsPipe, DateTimeToLocalPipe, DateTimeToFormatPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
