<div class="container-fluid">
  <div class="row bg-light">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="" (click)="goHome()">Product Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="" (click)="gotoCatDetail()">Product Category Detail</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ selectedProduct.name }}</li>
      </ol>
    </nav>
  </div>
  <div class="row">
    <div class="col-md-10">
      <h3 class="ml-3">Product Details</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 ml-5">
      <div>
        <div class="row mb-3">
          <div class="col-md-6">
            <table class="table-bordered">
              <tr>
                <td width="35%" class="card-title">Name:</td><td>{{ selectedProduct.name }}</td>
              </tr>
              <tr>
                <td class="card-title">Description:</td><td>{{ selectedProduct.description }}</td>
              </tr>
              <tr>
                <td class="card-title">Availability Date:</td><td>{{ selectedProduct.availabilityDate | dateTimeFromMilliseconds| dateTimeToLocal | dateTimeToFormat:'LLLL d, yyyy h:mm a' }} Local time</td>
              </tr>
              <tr>
                <td class="card-title">Deadline Date:</td><td>{{ selectedProduct.deadline | dateTimeFromMilliseconds| dateTimeToLocal | dateTimeToFormat:'LLLL d, yyyy h:mm a' }} Local time</td>
              </tr>
              <tr>
                <td class="card-title">Base Price:</td><td>{{ getBaseCost() | currency }}</td>
              </tr>
              <tr>
                <td class="card-title">Base Sku:</td><td>{{ selectedProduct.baseSkuNumber }}</td>
              </tr>
              <tr>
                <td class="card-title">Limited Threshold:</td><td>{{ selectedProduct.limitedThreshold }}</td>
              </tr>
              <tr>
                <td class="card-title">Is Sold Out?:</td><td>{{ selectedProduct.soldOut ? 'Yes' : 'No' }}</td>
              </tr>
              <tr>
                <td class="card-title">Is Visible?:</td><td>{{ selectedProduct.visible ? 'Yes' : 'No' }}</td>
              </tr>
              <tr>
                <td class="card-title">Allow Multiple?:</td><td>{{ selectedProduct.allowMultiple ? 'Yes' : 'No' }}</td>
              </tr>
              <tr>
                <td class="card-title">Committee:</td><td>{{ getCommitteeName() }}</td>
              </tr>
              <tr>
                <td class="card-title">Category:</td><td>{{ getCategoryName() }}</td>
              </tr>
              <tr>
                <td class="card-title">Shipping Type:</td><td>{{ getShippingTypeName() }}</td>
              </tr>
            </table>
          </div>
          <div class="col-md-5">
            <carousel [noWrap]="true" [interval]="false">
              <slide *ngFor="let slide of productImages; let index=index">
                <img [src]="getProductImage(slide)" alt="image slide" class="d-md-block h-100 mx-auto">
              </slide>
            </carousel>
          </div>
          <div class="col-md-1">
            <div class="dropdown text-center">
              <button class="btn btn-info dropdown-toggle" type="button" id="fullProductDrop" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                Actions
              </button>
              <div class="dropdown-menu" aria-labelledby="fullProductDrop">
                <a class="dropdown-item" [routerLink]="" (click)="openEditor(template, -1)">Edit Product</a>
                <a class="dropdown-item" [routerLink]="" (click)="addVariant(template3)">Add Variant</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="float-right">
      </div>
    </div>
  </div>
  <div *ngIf="gotVariants">
    <h5>Color Option Variants</h5>
    <div class="row"
         *ngFor="let prodVarkey of colorSizeArray | keyvalue;let index = index; trackBy: trackByIndexProduct">
      <div class="col-md-12">
        <div class="card w-75 mt-1">
          <div class="card-body">
            <div class="float-left">
              <h5 class="card-title">Product Color: {{ prodVarkey.key }}</h5>
            </div>
            <div class="float-right">
              <div class="dropdown text-center">
                <button class="btn btn-info dropdown-toggle" type="button" [id]="getDropdownId(index)"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Actions
                </button>
                <div class="dropdown-menu" [attr.aria-labelledby]="getDropdownId(index)">
                  <a class="dropdown-item" [routerLink]="" (click)="openEditor(template2, prodVarkey.key)">Edit This Variant</a>
                </div>
              </div>
            </div>
            <table class="table table-bordered table-sm table-tight ml-2 mb-0">
              <tr>
                <th>Size</th>
                <th>SKU</th>
                <th>Option Name</th>
                <th>Current Cost Variant</th>
                <th>Current Member Cost Variant</th>
                <th>Sold Out?</th>
                <th>Inventory</th>
              </tr>
              <tr *ngFor="let sizes of colorSizeArray.get(prodVarkey.key)">
                <td>{{ sizes.size.name }}</td>
                <td>{{ selectedProduct.baseSkuNumber + sizes.skuVariant }}</td>
                <td>{{ sizes.optionName}}</td>
                <td>{{ getCurrentCostVariant(sizes) | currency }} <button class="btn btn-sm btn-secondary ml-5">Change</button> </td>
                <td class="justify-content-between">{{ getCurrentMemberCostVariant(sizes) | currency }} <button class="btn btn-sm btn-secondary ml-5">Change</button> </td>
                <td>{{ sizes.soldOut }}</td>
                <td>{{ sizes.inventory }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <h5 class="mt-2">All Variants</h5>
    <div class="row">
      <div class="col-md-12">
        <div class="card w-75 mt-1">
          <div class="card-body">
            <div class="float-left">
              <h5 class="card-title">Product Name: {{ selectedProduct.name }}</h5>
            </div>
            <table class="table table-bordered table-sm table-tight ml-2 mb-0">
              <tr>
                <th>Display Name</th>
                <th>SKU</th>
                <th>Option Name</th>
                <th>Current Cost Variant</th>
                <th>Current Member Cost Variant</th>
                <th>Sold Out?</th>
                <th>Inventory</th>
              </tr>
              <tr *ngFor="let prodVarkey of selectedProduct.variants;let index = index; trackBy: trackByIndexProduct">
                <td>{{ prodVarkey.displayName }}</td>
                <td>{{ selectedProduct.baseSkuNumber + prodVarkey.skuVariant }}</td>
                <td>{{ prodVarkey.optionName }}</td>
                <td>{{ getCurrentCostVariant(prodVarkey) | currency }}</td>
                <td>{{ getCurrentMemberCostVariant(prodVarkey) | currency }}</td>
                <td>{{ prodVarkey.soldOut }}</td>
                <td>{{ prodVarkey.inventory }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="!gotVariants">
    <h6 class="ml-3">No Product Variants Found</h6>
  </div>
  <div *ngIf="dataPointKeyCount > 0">
    <div class="row">
      <div class="col-md-10">
        <div class="card w-75 mt-1">
          <div class="card-body">
            <div class="float-left">
              <h6 class="text-muted">Custom Data:</h6>
            </div>
            <table class="table table-bordered table-sm table-tight ml-2 mb-0 bg-white">
              <tr *ngFor="let akey of objectKeys(dataPointsJson)" class="border-bottom">
                <td>{{ akey }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="dataPointKeyCount === 0">
    <h6 class="ml-3">No Custom Data Found</h6>
  </div>

  <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Edit Product - {{ selectedProduct.name }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label for="changeAvail" class="slider-form-label">Product Availability:
        <div class="switch ml-2">
          <input type="checkbox" class="form-control" name="avail" (change)="changeAvail()" id="changeAvail"
                 [(ngModel)]="isAvail" [checked]="isAvail">
          <span class="slider round"></span>
        </div>
      </label>
    </div>
  </ng-template>

  <ng-template #template2>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Edit Product Variant - {{ variantName }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5>Change values for this variant of the product</h5>
      <form [formGroup]="variantForm">
        <table class="table table-bordered table-sm table-tight ml-2 mb-0">
          <tr>
            <th>Size</th>
            <th>SKU</th>
            <th>Sold Out?</th>
            <th>Inventory</th>
            <th>Display Name</th>
            <th>Option Name</th>
          </tr>
          <tr *ngFor="let sizes of colorSizeArray.get(variantName);let index = index; trackBy: trackByIndexVariant">
            <td>{{ sizes.size.name }}</td>
            <td>{{ selectedProduct.baseSkuNumber + sizes.skuVariant }}</td>
            <td>
              <label class="switch ml-2">
                <input type="checkbox" class="form-control" [name]="getsoldoutline(index)" [formControlName]="getsoldoutline(index)"
                       (change)="changeSoldOut(index)">
                <span class="slider round"></span>
              </label>
            <td><input type="text" [name]="getinventoryline(index)" [formControlName]="getinventoryline(index)"></td>
            <td><input type="text" [name]="getdnameline(index)" [formControlName]="getdnameline(index)"></td>
            <td><input type="text" [name]="getonameline(index)" [formControlName]="getonameline(index)"></td>
          </tr>
        </table>
      </form>
    </div>
    <div class="modal-footer">
      <div>
        <button type="button" class="btn btn-secondary" aria-label="Cancel" (click)="modalRef.hide()">
          <span aria-hidden="true">Cancel</span>
        </button>
        <button type="button" class="btn btn-success ml-5" aria-label="Save" (click)="saveEdit()">
          <span aria-hidden="true">Save</span>
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #template3>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Add Product Variant</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5>Select the color and required sizes of this variant</h5>
      <form [formGroup]="addVariantForm">
        <table class="table table-borderless table-sm table-tight ml-2 mb-0">
          <tr>
            <td>Select Color</td>
            <td>
              <select formControlName="addedColor">
                <option *ngFor="let color of allColors; let index = index;" [value]="color.id">{{ color.name }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Select Sizes</td><td></td>
          </tr>
          <tr *ngFor="let size of allSizes;let index = index;">
            <td></td><td><input type="checkbox" [name]="size.name" [formControlName]="size.name"><span class="ml-2">{{ size.name }}</span></td>
          </tr>
        </table>
      </form>
    </div>
    <div class="modal-footer">
      <div>
        <button type="button" class="btn btn-secondary" aria-label="Cancel" (click)="modalRef.hide()">
          <span aria-hidden="true">Cancel</span>
        </button>
        <button type="button" class="btn btn-success ml-5" aria-label="Save" (click)="saveVariant()">
          <span aria-hidden="true">Save</span>
        </button>
      </div>
    </div>
  </ng-template>

</div>
