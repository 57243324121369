import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Order, SubOrder} from '../models/orders.model';
import {environment} from '../../environments/environment';

@Injectable()
export class OrdersService {
  constructor(private _api: ApiService,
              private _http: HttpClient) {}

  public getOrdersTable(body: any): Observable<any> {
    return this._api.post('/orders/table', body);
  }

  public getAnOrder(orderId: number): Observable<Order> {
    return this._api.get('/orders/' + orderId);
  }

  public getSubOrdersTable(body: any, committeeId: string): Observable<any> {
    return this._api.post('/orders/table?comId=' + committeeId, body);
  }

  public getSuborderDetails(subOrderId: number): Observable<SubOrder> {
    return this._api.get('/suborders/' + subOrderId);
  }

  public  shipSuborder(subOrder: SubOrder): Observable<any> {
    return this._api.put('/suborders/' + subOrder.id, subOrder);
  }

  public  shipOrder(order: Order): Observable<any> {
    return this._api.put('/orders/' + order.id + '/ship', order);
  }

  public  getOrdersChart(): Observable<any> {
    return this._api.get('/orders/metrics');
  }

  public getAllOrders(state): Observable<any> {
    return this._api.get('/orders?state=' + state + '&eventId=' + environment.currentEventId);
  }

  public getAllProdOrders(): Observable<any> {
    return this._api.get('/orders?type=prod&eventId=' + environment.currentEventId);
  }
  public getSkuReport(): Observable<any> {
    return this._api.get('/orders/reports/fullsku?eventId=' + environment.currentEventId);
  }

  public getProfitReport(): Observable<any> {
    return this._api.get('/orders/reports/profit?eventId=' + environment.currentEventId);
  }

  public getMerchReport(): Observable<any> {
    return this._api.get('/orders/reports/merchandise?eventId=' + environment.currentEventId);
  }

  public getInvoiceInfo(invoiceId: string): Observable<any> {
    return this._api.get('/invoices/' + invoiceId);
  }

  public refundOrder(body: any): Observable<any> {
    return this._api.post('/payment/refund', body);
  }
}
