import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import {modes} from '../models/modes.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationData} from '../shared/validationMessages.component';
import {NatSpecSize} from '../models/sizes.model';
import {SizesService} from '../services/sizes.service';

@Component({
  selector: 'app-manage-sizes',
  templateUrl: './manage-sizes.component.html',
  styleUrls: ['./manage-sizes.component.css']
})
export class ManageSizesComponent implements OnInit, AfterViewInit {

  sizes: NatSpecSize[];
  mySpinner: boolean;
  mode: number;
  selectedSize: NatSpecSize;
  sizeForm: FormGroup;

  validationData: ValidationData = {
    'sizeName': [
      {type: 'required', message: 'Please enter a name for this size'}
    ]
  };

  constructor(private api: ApiService,
              private service: SizesService,
              private formBuilder: FormBuilder,
              private ngZone: NgZone) {
    this.sizeForm = this.formBuilder.group({
      sizeName: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.mode = modes.TABLEVIEW;
    this.selectedSize = null;
  }

  ngAfterViewInit(): void {
    this.refreshSizes();
  }

  refreshSizes() {
    this.mode = modes.TABLEVIEW;
    setTimeout(() => {
      this.mySpinner = true;
      this.service.getSizes().subscribe((res) => {
        this.ngZone.run(() => {
          this.sizes = res;
          this.mySpinner = false;
        });
      });
    }, 500);
  }
  addNewSize() {
    this.selectedSize = {id: -1, name: ''};
    const controls = this.sizeForm.controls;
    const size = this.selectedSize;
    controls.sizeName.setValue(size.name);

    this.mode = modes.EDITVIEW;
  }
  editSize() {
    const controls = this.sizeForm.controls;
    const size = this.selectedSize;
    controls.sizeName.setValue(size.name);

    this.mode = modes.EDITVIEW;
  }
  saveSize() {
    this.sizeForm.updateValueAndValidity();
    if (!this.sizeForm.valid) {
      return;
    }
    this.selectedSize.name = this.sizeForm.controls.sizeName.value;

    if (this.selectedSize.id === -1) {
      this.service.newSize(this.selectedSize)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshSizes();
          });
        });
    } else {
      this.service.updateSize(this.selectedSize, this.selectedSize.id)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshSizes();
          });
        });
    }
  }

  sizeDetails(index) {
    this.selectedSize = this.sizes[index];
    this.mode = modes.DETAILVIEW;
  }

  isTableViewMode() {
    return this.mode === modes.TABLEVIEW;
  }
  isDetailViewMode() {
    return this.mode === modes.DETAILVIEW;
  }
  isEditViewMode() {
    return this.mode === modes.EDITVIEW;
  }
}
