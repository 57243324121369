import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductsService} from '../services/products.service';
import {
  NatSpecAddVariantBody,
  NatSpecProduct,
  NatSpecProductImage,
  NatSpecProductVariant,
  Product,
  ProductSize,
  ProductVariant
} from '../models/products.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {select, Store} from '@ngrx/store';
import {getCurrentUser} from '../store/reducers';
import {getUsers, UserState} from '../store/reducers/user.reducer';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationData} from '../shared/validationMessages.component';
import {SizesService} from '../services/sizes.service';
import {NatSpecSize} from '../models/sizes.model';
import {NatSpecColor} from '../models/colors.model';
import {ColorsService} from '../services/colors.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit, AfterViewInit {
  public pageTitle = 'Product Management';
  selectedProduct: NatSpecProduct = {variants: []};
  mySpinner: boolean;
  cloneProductList;
  private sub: any;
  prodId: number;
  productName: string;
  colorSizeArray: Map<string, any[]>;
  dataPointKeyCount: number;
  dataPointsJson: any;
  objectKeys = Object.keys;
  modalRef: BsModalRef;
  isAvail: boolean;
  variantName: string;
  gotVariants: boolean;
  currentUser: UserState = {
    user: {myId: -1, email: '', first: '', last: '' },
    authenticated: false,
    token: ''};
  variantForm: FormGroup;
  addVariantForm: FormGroup;
  variantControlNames: any[] = [];
  variantsChangedArray: NatSpecProductVariant[] = [];
  variantsUpdatedCount: number;
  allSizes: NatSpecSize[];
  allColors: NatSpecColor[];
  productImages: NatSpecProductImage;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private formBuilder: FormBuilder,
              private changeDetect: ChangeDetectorRef,
              private modalService: BsModalService,
              private service: ProductsService,
              private sizesService: SizesService,
              private colorService: ColorsService,
              private store: Store<UserState>
  ) {
    this.variantForm = this.formBuilder.group({});
    this.addVariantForm = this.formBuilder.group({
      addedColor: [-1, Validators.required]
    });
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
    });
  }

  ngOnInit() {
    this.colorSizeArray = new Map();
    this.mySpinner = false;
    this.productName = '';
    this.dataPointKeyCount = 0;
    this.isAvail = true;
    this.variantName = '';
    this.gotVariants = false;
    this.dataPointsJson = {};
  }

  ngAfterViewInit() {
    this.sub = this.route.paramMap.subscribe(params => {
      this.ngZone.run(() => {
        this.prodId = +params.get('prodId'); // (+) converts string 'id' to a number
        this.refreshProductInfo();
      });
    });
    this.sizesService.getSizes()
      .subscribe((sizeRes) => {
        this.ngZone.run(() => {
          this.allSizes = sizeRes;
          if (this.allSizes !== null) {
            this.allSizes.forEach((aSize) => {
              const sizeControl: FormControl = new FormControl({value: false, disabled: false});
              this.addVariantForm.addControl(aSize.name, sizeControl);
            });
          }
        });
      });
    this.colorService.getColors()
      .subscribe((colorRes) => {
        this.ngZone.run(() => {
          this.allColors = colorRes;
        });
      });
   }

  refreshProductInfo() {
    this.mySpinner = true;
    this.service.getProduct(this.prodId).subscribe((res) => {
      this.ngZone.run(() => {
        this.selectedProduct = res;
        this.productImages = res.images;
        this.findProducts();
        if (this.selectedProduct.variants.length > 0) {
          this.gotVariants = true;
        }
        this.dataPointsJson = JSON.parse((this.selectedProduct.dataPoints));
        this.dataPointKeyCount = Object.keys(this.dataPointsJson).length;
        this.isAvail = this.selectedProduct.availabilityDate !== 0;
        if (!this.changeDetect['destroyed']) {
          this.changeDetect.detectChanges();
          this.mySpinner = false;
        }
      });
    });
  }
  addVariant(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  openEditor(template: TemplateRef<any>, prodKey) {
    if (prodKey !== '') {
      // we're editing a VARIANT
      this.variantName = prodKey;
      this.variantControlNames.forEach((controlName) => {
        this.variantForm.removeControl(controlName);
      });
      this.variantControlNames = [];
      // now setup controls for editing variant
      let indexCount = 0;
      this.colorSizeArray.get(this.variantName).forEach((sizeName) => {
        const soldOut = sizeName.soldOut;
        const sizeControl: FormControl = new FormControl({value: soldOut, disabled: false});
        const conName = this.getsoldoutline(indexCount);
        this.variantForm.addControl(conName, sizeControl);
        this.variantControlNames.push(conName);
        const invent = sizeName.inventory;
        const invControl: FormControl = new FormControl({value: invent, disabled: false});
        const con2Name = this.getinventoryline(indexCount);
        this.variantForm.addControl(con2Name, invControl);
        this.variantControlNames.push(con2Name);
        const dname = sizeName.displayName;
        const dnameControl: FormControl = new FormControl({value: dname, disabled: false});
        const con3Name = this.getdnameline(indexCount);
        this.variantForm.addControl(con3Name, dnameControl);
        this.variantControlNames.push(con3Name);
        const oname = sizeName.optionName;
        const onameControl: FormControl = new FormControl({value: oname, disabled: false});
        const con4Name = this.getonameline(indexCount);
        this.variantForm.addControl(con4Name, onameControl);
        this.variantControlNames.push(con4Name);
        indexCount++;
      });
      this.modalRef = this.modalService.show(template, {class: 'modal-xl'});
    } else {
      this.modalRef = this.modalService.show(template);
    }
  }

  saveVariant() {
    const variantsAddedArray = [];
    this.allSizes.forEach((sizeName) => {
      const sizeControl = this.addVariantForm.controls[sizeName.name];
      if (sizeControl.value) {
        variantsAddedArray.push(sizeName.id);
      }
    });
    if (variantsAddedArray.length > 0) {
      const colorChoice = this.addVariantForm.controls.addedColor.value;
      if (colorChoice >= 0) {
        const formBody: NatSpecAddVariantBody = {color: colorChoice, sizes: variantsAddedArray};
        this.service.addVariants(formBody, this.prodId)
          .subscribe((varRes) => {
            this.ngZone.run(() => {
              this.modalRef.hide();
              this.refreshProductInfo();
            });
          });
      } else {
        // show an error
      }
    } else {
      this.modalRef.hide();
    }

  }
  saveEdit() {
    // look for what to update
    this.variantsChangedArray = [];
    let indexCount = 0;
    this.colorSizeArray.get(this.variantName).forEach((sizeName) => {
      let thisOneChanged = false;
      const conName = this.getsoldoutline(indexCount);
      const soldoutControl = this.variantForm.controls[conName];
      if (soldoutControl.value !== sizeName.soldOut) {
        sizeName.soldOut = soldoutControl.value;
        thisOneChanged = true;
      }
      const con2Name = this.getinventoryline(indexCount);
      const invControl = this.variantForm.controls[con2Name];
      if (invControl.value !== sizeName.inventory) {
        sizeName.inventory = invControl.value;
        thisOneChanged = true;
      }
      const con3Name = this.getdnameline(indexCount);
      const displayControl = this.variantForm.controls[con3Name];
      if (displayControl.value !== sizeName.displayName) {
        sizeName.displayName = displayControl.value;
        thisOneChanged = true;
      }
      const con4Name = this.getonameline(indexCount);
      const optionControl = this.variantForm.controls[con4Name];
      if (optionControl.value !== sizeName.optionName) {
        sizeName.optionName = optionControl.value;
        thisOneChanged = true;
      }
      if (thisOneChanged) {
        this.variantsChangedArray.push(sizeName);
      }
      indexCount++;
    });
    if (this.variantsChangedArray.length > 0) {
      this.variantsUpdatedCount = 0;
      this.variantsChangedArray.forEach((variant) => {
        this.service.updateProductVariantInventory(variant, variant.id)
          .subscribe((res) => {
            this.ngZone.run(() => {
              this.variantsUpdatedCount++;
              if (this.variantsUpdatedCount === this.variantsChangedArray.length) {
                this.modalRef.hide();
                this.refreshProductInfo();
              }
            });
          });
      });
    } else {
      this.modalRef.hide();
    }
  }
  markUnavail() {
  }

  findProducts() {
    // loop through Variants and find matches sizes
    const prodVars: ProductVariant[] = this.selectedProduct.variants;
    prodVars.forEach((aVar) => {
      if (aVar.color !== null) {
        const prodColor = aVar.color.name;
        if (this.colorSizeArray.has(prodColor)) {
          const varArray = this.colorSizeArray.get(prodColor);
          varArray.push(aVar);
        } else {
          this.colorSizeArray.set(prodColor, new Array(aVar));
        }
      }
    });
  }

  goback() {
    this.router.navigate(['admin'], { queryParams: {path: 'products'}});
  }

  gotoCatDetail() {
    this.router.navigateByUrl('product-category-detail/' + this.selectedProduct.category.id);
  }
  goHome() {
    this.router.navigateByUrl('admin');
  }

  trackByIndexVariant(index, item): any {
    return index;
  }

  trackByIndexProduct(index, item): any {
    return index;
  }

  getDropdownId(index) {
    return 'dropdownmenu' + index;
  }

  changeAvail() {
    if (!this.changeDetect['destroyed']) {
      this.changeDetect.detectChanges();
    }
  }

  changeSoldOut(index) {
    if (!this.changeDetect['destroyed']) {
      this.changeDetect.detectChanges();
    }
  }

  getcostline(index) {
    return 'costLine' + index;
  }
  getsoldoutline(index) {
    return 'soldoutLine' + index;
  }
  getinventoryline(index) {
    return 'inventoryLine' + index;
  }
  getdnameline(index) {
    return 'displayNameLine' + index;
  }
  getonameline(index) {
    return 'optionNameLine' + index;
  }

  getProductCost(sizes: ProductVariant) {
    return (parseFloat(this.selectedProduct.base_prices[0].basePrice) + parseFloat(sizes.costVariant));
  }

  getCurrentCostVariant(sizes: NatSpecProductVariant) {
    let variantExtra = 0.0;
    const curtime = new Date().getTime();
    sizes.cost_variants.forEach((aCostVariant) => {
      if (((aCostVariant.endDate === null) || (aCostVariant.endDate > curtime)) && (curtime >= aCostVariant.startDate)) {
        variantExtra = parseFloat(aCostVariant.costVariant);
      }
    });
    return variantExtra;
  }
  getCurrentMemberCostVariant(sizes: NatSpecProductVariant) {
    let memberVariantExtra = 0.0;
    const curtime = new Date().getTime();
    sizes.member_cost_variants.forEach((aCostVariant) => {
      if (((aCostVariant.endDate === null) || (aCostVariant.endDate > curtime)) && (curtime >= aCostVariant.startDate)) {
        memberVariantExtra = parseFloat(aCostVariant.memberVariant);
      }
    });
    return memberVariantExtra;
  }

  getBaseCost() {
    let basePrice = 0.0;
    const curtime = new Date().getTime();
    if (this.selectedProduct.base_prices !== undefined) {
      this.selectedProduct.base_prices.forEach((aPrice) => {
        if (((aPrice.endDate === null) || (aPrice.endDate > curtime)) && (curtime >= aPrice.startDate)) {
          basePrice = parseFloat(aPrice.basePrice);
        }
      });
    }
    return basePrice;
  }

  getProductImage(theImage: NatSpecProductImage) {
    return 'assets/images/product/' + theImage.path;
  }

  getCommitteeName() {
    if (this.selectedProduct.committee !== undefined) {
      return this.selectedProduct.committee.name;
    }
  }
  getCategoryName() {
    if (this.selectedProduct.category !== undefined) {
      return this.selectedProduct.category.name;
    }
  }
  getShippingTypeName() {
    if (this.selectedProduct.shipping_type !== undefined) {
      return this.selectedProduct.shipping_type.name;
    }
  }

}
