<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 bg-light">
      <div class="order-toc treeview">
        <tree-root [nodes]="nodes" [options]="options" (activate)="onEvent($event)" class="branch-lines"
                   (toggleExpanded)="onEvent($event)"></tree-root>
      </div>
      <hr>
      <div>
        <div *ngIf="showingPath === 'admin'">
          <app-admin-help></app-admin-help>
        </div>
        <div *ngIf="showingPath === 'orders'">
          <app-orders-help></app-orders-help>
        </div>
        <div *ngIf="showingPath === 'suborders'">
          <app-suborders-help></app-suborders-help>
        </div>
        <div *ngIf="showingPath === 'forms'">
          <app-forms-help></app-forms-help>
        </div>
        <div *ngIf="showingPath === 'sms'">
          <app-sms-help></app-sms-help>
        </div>
        <div *ngIf="showingPath === 'finance'">
          <app-finance-reports-help></app-finance-reports-help>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div *ngIf="showingPath === 'admin'">
        <h2>Admin Home</h2>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button id="perDayButton" type="button" [ngClass]="buttonClassForOption('Per Day')" (click)="buttonChanged('Per Day')">Per Day</button>
          <button id="perWeekButton" type="button" [ngClass]="buttonClassForOption('Per Week')" (click)="buttonChanged('Per Week')">Per Week</button>
          <button id="perMonthButton" type="button" [ngClass]="buttonClassForOption('Per Month')" (click)="buttonChanged('Per Month')">Per Month</button>
          <button id="allTimeButton" type="button" [ngClass]="buttonClassForOption('All Time')" (click)="buttonChanged('All Time')">All Time</button>
        </div>
        <div style="display: block;position: relative; height:30vh; width:70vw">
          <canvas #countChart baseChart
                  [datasets]="ordersPerDayData"
                  [labels]="ordersPerDayLabels"
                  [options]="lineChartOptions"
                  [colors]="lineChartColors"
                  [chartType]="ordersPerDayType"></canvas>
        </div>
        <div style="margin-top: 20px;display: block;position: relative; height:30vh; width:70vw">
          <canvas #revChart baseChart
                  [datasets]="revPerDayData"
                  [labels]="revPerDayLabels"
                  [options]="lineChartOptions"
                  [colors]="lineChartColors2"
                  [chartType]="revPerDayType"></canvas>
        </div>
      </div>
      <div *ngIf="showingPath === 'orders'">
        <app-orders></app-orders>
      </div>
      <div *ngIf="showingPath === 'suborders'">
        <app-suborders></app-suborders>
      </div>
      <div *ngIf="showingPath === 'forms'">
        <app-forms></app-forms>
      </div>
      <div *ngIf="showingPath === 'sms'">
        <app-sms></app-sms>
      </div>
      <div *ngIf="showingPath === 'finance'">
        <app-finance-reports></app-finance-reports>
      </div>
      <div *ngIf="showingPath === 'system'">
        <app-system-admin></app-system-admin>
      </div>
      <div *ngIf="showingPath === 'manageevs'">
        <app-manage-events></app-manage-events>
      </div>
      <div *ngIf="showingPath === 'managecomms'">
        <app-manage-committees></app-manage-committees>
      </div>
      <div *ngIf="showingPath === 'managecats'">
        <app-manage-categories></app-manage-categories>
      </div>
      <div *ngIf="showingPath === 'managecols'">
        <app-manage-colors></app-manage-colors>
      </div>
      <div *ngIf="showingPath === 'managesizes'">
        <app-manage-sizes></app-manage-sizes>
      </div>
      <div *ngIf="showingPath === 'manageprods'">
        <app-manage-products></app-manage-products>
      </div>
    </div>
  </div>
</div>
