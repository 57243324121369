import { RouterModule, Routes } from '@angular/router';
import { NgModel } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { OrdersComponent } from './orders/orders.component';
import { ProductsComponent } from './products/products.component';
import { ShippingComponent } from './shipping/shipping.component';
import {OrderDetailComponent} from './order-detail/order-detail.component';
import {ProductCategoryDetailComponent} from './product-category-detail/product-category-detail.component';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import {SubordersComponent} from './suborders/suborders.component';
import {PrintLayoutComponent} from './print-layout/print-layout.component';
import {PackingSlipComponent} from './packing-slip/packing-slip.component';
import {SystemAdminComponent} from './system-admin/system-admin.component';
import {ManageEventsComponent} from './manage-events/manage-events.component';
import {ManageCommitteesComponent} from './manage-committees/manage-committees.component';
import {ManageCategoriesComponent} from './manage-categories/manage-categories.component';
import {ManageColorsComponent} from './manage-colors/manage-colors.component';
import {ManageSizesComponent} from './manage-sizes/manage-sizes.component';
import {ManageProductsComponent} from './manage-products/manage-products.component';
import {FormsComponent} from './forms/forms.component';
import {FormsDetailComponent} from './forms-detail/forms-detail.component';
import {SmsComponent} from './sms/sms.component';
import {SuborderDetailComponent} from './suborder-detail/suborder-detail.component';
import {FinanceReportsComponent} from './finance-reports/finance-reports.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'admin', component: AdminComponent
  },
  {
    path: 'orders', component: OrdersComponent
  },
  {
    path: 'products', component: ProductsComponent
  },
  {
    path: 'forms', component: FormsComponent
  },
  {
    path: 'sms', component: SmsComponent
  },
  {
    path: 'shipping', component: ShippingComponent
  },
  {
    path: 'order-detail/:orderId', component: OrderDetailComponent
  },
  {
    path: 'forms-detail/:formId', component: FormsDetailComponent
  },
  {
    path: 'product-category-detail/:catId', component: ProductCategoryDetailComponent
  },
  {
    path: 'product-detail/:prodId', component: ProductDetailComponent
  },
  {
    path: 'suborders', component: SubordersComponent
  },
  {
    path: 'suborder-detail/:orderId', component: SuborderDetailComponent
  },
  {
    path: 'system', component: SystemAdminComponent
  },
  {
    path: 'finance', component: FinanceReportsComponent
  },
  {
    path: 'manageevs', component: ManageEventsComponent
  },
  {
    path: 'managecomms', component: ManageCommitteesComponent
  },
  {
    path: 'managecats', component: ManageCategoriesComponent
  },
  {
    path: 'managecols', component: ManageColorsComponent
  },
  {
    path: 'managesizes', component: ManageSizesComponent
  },
  {
    path: 'manageprods', component: ManageProductsComponent
  },
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'slip/:subOrderId', component: PackingSlipComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
