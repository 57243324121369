import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as userReducer from './user.reducer';
import {UserState} from './user.reducer';

export interface AppState {

  userState: UserState;
}

export const reducers = {

  userState: userReducer.reducer,
};


export const metaReducers: MetaReducer<UserState>[] = !environment.production ? [] : [];

export const selectUserState = createFeatureSelector<UserState>('userState');
export const getUserName = createSelector(selectUserState, userReducer.getUserName);
export const getUserEmail = createSelector(selectUserState, userReducer.getUserEmail);
export const getUserId = createSelector(selectUserState, userReducer.getUserId);
export const getCurrentUser = createSelector(selectUserState, userReducer.getUsers);
