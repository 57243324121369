import { Pipe, PipeTransform } from '@angular/core';

/**
 * A simple object filter, since Angular does not yet have a filter pipe built in.
 */
@Pipe({
  name: 'stringFilter'
})
export class StringFilterPipe implements PipeTransform {

  transform(categories: any[], q: string) {
    if (!q || q === '' || q === 'custom-') {
      return categories;
    }
    q = q.toLowerCase();
    if (q.startsWith('custom-')) {
      if (q.length > 6) {
        const keyw = q.substring(7);
        return categories.filter( item => {
          return ((item.name.toLowerCase().indexOf(keyw) !== -1) ||
            (item.description.toLowerCase().indexOf(keyw) !== -1));
        });
      } else {
        return categories;
      }
    } else {
      return categories.filter(item => {
        let retVal = false;
        switch (q) {
          case 'merch':
            if (item.product_type.id === 1) {
              retVal = true;
            }
            break;
          case 'reserve':
            if (item.product_type.id === 2) {
              retVal = true;
            }
            break;
        }
        return retVal;
      });
    }
  }
}
