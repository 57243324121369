import {AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {NatSpecProduct, Product} from '../models/products.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductsService} from '../services/products.service';
import {select, Store} from '@ngrx/store';
import {getUsers, UserState} from '../store/reducers/user.reducer';
import {getCurrentUser} from '../store/reducers';

@Component({
  selector: 'app-product-category-detail',
  templateUrl: './product-category-detail.component.html',
  styleUrls: ['./product-category-detail.component.css']
})
export class ProductCategoryDetailComponent implements OnInit {
  public pageTitle = 'Product Management';
  products: NatSpecProduct[];
  mySpinner: boolean;
  cloneProductList;
  private sub: any;
  catId: number;
  categoryName: string;
  currentUser: UserState = {
      user: {myId: -1, email: '', first: '', last: '' , committeeId: '(0)'},
      authenticated: false,
      token: ''};

  constructor(private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private changeDetect: ChangeDetectorRef,
              private service: ProductsService,
              private store: Store<UserState>
  ) {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
    });
  }

  ngOnInit() {
    this.mySpinner = false;
    this.categoryName = '';
    this.sub = this.route.paramMap.subscribe(params => {
      this.catId = +params.get('catId'); // (+) converts string 'id' to a number
      this.mySpinner = true;
      this.service.getProducts(this.catId).subscribe((res) => {
        this.ngZone.run(() => {
          this.products = res.products;
          if (this.products.length > 0) {
            if (!this.changeDetect['destroyed']) {
              this.changeDetect.detectChanges();
            }
          }
          this.mySpinner = false;
        });
      });
    });
  }

  gotoDetails(product) {
    this.router.navigateByUrl('product-detail/' + product.id);
  }
  goHome() {
    this.router.navigateByUrl('admin');
  }
}
