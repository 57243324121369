<div *ngIf="!isAllowed" class="row">
  <div class="col-md-12">
    <h3>This page is only available to the treasurer</h3>
  </div>
</div>
<div *ngIf="isAllowed" class="container-fluid">
  <div class="row bg-light">
    <div class="col-sm-12 mx-auto d-block">
      <!-- <pre>{{ masterList | json}}</pre> -->
      <h3>Select the report you would like to generate</h3>
      <div class="grid">
        <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
        <div class="prod-cat-card" *ngFor="let report of reportList" (click)="startReport(report)">
          <app-report-card [item]="report"></app-report-card>
        </div>
      </div>
    </div>
  </div>
  <div class="row bg-light">
    <div class="col-sm-6 mx-auto d-block">
      <table class="profit-table">
        <tr>
          <th colspan="2"><h3>Merchandise Profit Info</h3></th>
        </tr>
        <tr>
          <td>Invoice Total:</td><td>{{ merchInvoiceTotal | currency }}</td>
        </tr>
        <tr>
          <td>Revenue Total:</td><td>{{ merchRevenueTotal | currency }}</td>
        </tr>
        <tr>
          <td>Profit:</td><td>{{ (merchRevenueTotal - merchInvoiceTotal) | currency }}</td>
        </tr>
        <tr>
          <td></td><td></td>
        </tr>
        <tr>
          <td>Shipped Orders:</td><td>{{ shippedOrders }}</td>
        </tr>
        <tr>
          <td>Unshipped Orders:</td><td>{{ unshippedOrders }}</td>
        </tr>
      </table>
      <button type="button" class="btn btn-primary" (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">Show/Hide Unshipped Orders
      </button>
      <hr>
      <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
        <div class="well well-lg card card-block card-header">
          <h5>Click order number for details</h5>
          <ul>
            <li style="cursor: pointer;" *ngFor="let anOrder of unshippedOrdersArray;let index = index;" (click)="gotoOrder(index)">{{ anOrder.id }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
