import {Component, NgZone, OnInit} from '@angular/core';
import {getCurrentUser} from '../store/reducers';
import {UserState} from '../store/reducers/user.reducer';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {OrdersService} from '../services/orders.service';
import {Store} from '@ngrx/store';
import {ExcelService} from '../services/excel.service';
import {MatDialog} from '@angular/material/dialog';
import {QueryModalComponent} from '../query-modal/query-modal.component';
import {DateTimeFromMillisecondsPipe, DateTimeToFormatPipe, DateTimeToLocalPipe} from 'luxon-angular';
import {OrderItem, UnshippedOrders} from '../models/orders.model';

@Component({
  selector: 'app-finance-reports',
  templateUrl: './finance-reports.component.html',
  styleUrls: ['./finance-reports.component.css']
})
export class FinanceReportsComponent implements OnInit {

  isAllowed = false;
  merchRevenueTotal = 0;
  merchInvoiceTotal = 0;
  shippedOrders = 0;
  unshippedOrders = 0;
  unshippedOrdersArray:  UnshippedOrders[] = [];
  isCollapsed = true;
  currentUser: UserState = {
    user: {myId: -1, email: '', first: '', last: '' },
    authenticated: false,
    token: ''};
  reportList = [ {
      name: 'All Sku Export Report', description: 'List totals for each product'
    },
    {
      name: 'Full Order Export', description: 'List of all orders and product totals'
    },
    {
      name: 'Merchandise Order Report', description: 'Status of all Merchandise Orders'
    },
    {
      name: 'Invoice Report', description: 'Report of items for an invoice'
    }
    ];
  mySpinner: boolean;

  constructor(private router: Router,
              private service: OrdersService,
              private store: Store<UserState>,
              private excelService: ExcelService,
              private dialog: MatDialog,
              private fromMSPipe: DateTimeFromMillisecondsPipe,
              private dtToLocalPipe: DateTimeToLocalPipe,
              private dtToFormatPipe: DateTimeToFormatPipe,
              private ngZone: NgZone) { }

  ngOnInit(): void {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      // Todo: Update this to the new treasurer every year
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
      if (this.currentUser.user.myId === 28792390 ||
        this.currentUser.user.myId === 34400209 ||
        this.currentUser.user.myId === 34404855) {
        this.isAllowed = true;
      }
      this.getMerchProfit();
    });
  }

  startReport(report) {
    switch (report.name) {
      case 'All Sku Export Report':
        this.generateAllSkuReport();
        break;
      case 'Full Order Export':
        this.generateFullExportReport();
        break;
      case 'Merchandise Order Report':
        this.generateMerchReport();
        break;
      case 'Invoice Report':
        this.generateInvoiceReport();
        break;
    }
  }

  getMerchProfit() {
    this.service.getProfitReport().subscribe((res) => {
      this.ngZone.run(() => {
        if (res.status === 'success') {
          this.merchInvoiceTotal = parseFloat(res.invoiceData.invoiceTotal);
          this.merchRevenueTotal = parseFloat(res.invoiceData.revenueTotal);
          this.shippedOrders = res.shipped.shipped;
          this.unshippedOrdersArray = res.unshipped;
          this.unshippedOrders = this.unshippedOrdersArray.length;
        }
      });
    });
  }
  generateAllSkuReport() {
    this.mySpinner = true;
    this.service.getSkuReport().subscribe((res) => {
      this.ngZone.run(() => {
        const skuData = [];
        let fullTotal = 0;
        res.forEach((aSku) => {
          fullTotal = fullTotal + parseFloat(aSku.Cost);
          const newSku = {
            Name: aSku.name, Display_Name: aSku.displayName, SKU: aSku.SKU, Color: aSku.Color, Size: aSku.Size,
            Quantity: parseFloat(aSku.quant), Total_Revenue: parseFloat(aSku.Cost)
          };
          skuData.push(newSku);
        });
        const totals = {
          Name: '', Display_Name: '', SKU: '', Color: '', Size: '', Quantity: 'Total:', Total_Revenue: fullTotal
        };
        skuData.push(totals);
        this.excelService.exportAsExcelFile(skuData, 'FullSkuReport', [6]);
        this.mySpinner = false;
      });
    });
  }

  generateFullExportReport() {
    this.mySpinner = true;
    this.service.getAllProdOrders( ).subscribe((res) => {
      this.ngZone.run(() => {
        const orderData = [];
        const colTotals = [];
        const committeeColumns = [
          'Canine Cardiology Clinic',
          'Canine Good Citizen',
          'Canine Opthamology Clinic',
          'Catalog Advertising',
          'Donations',
          'Golf Cart Reservations',
          'Grooming Reservations',
          'Handler Parking',
          'Honors Parade',
          'International Packages',
          'Living Legend Merchandise',
          'Living Legends',
          'Logo Merchandise',
          'Meal Reservations',
          'Post Show Catalogs',
          'Preferred Ringside Seating',
          'Registration',
          'Reserved Ringside Seating',
          'RV Camping & RV Parking',
          'Specialty Carting & Wagon Exercises',
          'Top Dogs Celebration',
          'Top Dogs Merchandise',
          'Top Twenty \/ Ten Celebration',
          'Top Twenty \/ Ten Merchandise',
          'Trick Dog Testing',
          'Trophies',
          'VIP Package Committee',
          'Water Orders'
        ];
        committeeColumns.forEach((aComCol) => {
          colTotals[aComCol] = 0;
        });
        colTotals['Total_Revenue'] = 0;
        colTotals['Refunded_Amount'] = 0;
        res.forEach((order) => {
          const tcost = parseFloat(order.totalCost);
          const refAmount = parseFloat(order.refundedAmount);
          colTotals['Total_Revenue'] = colTotals['Total_Revenue'] + tcost;
          colTotals['Refunded_Amount'] = colTotals['Refunded_Amount'] + refAmount;
          const orderReadableDate = moment(order.orderDate).format('LLL');
          const anOrder = {
            Order_Id: order.id, Order_Date: orderReadableDate, Total_Revenue: tcost, Refunded_Amount: refAmount,
            Customer_Name: order.customer.firstName + ' ' + order.customer.lastName, Customer_Email: order.customer.email
          };
          const subsTotals = [];
          committeeColumns.forEach((aComCol) => {
            subsTotals[aComCol] = 0;
          });
          // let refAmount = 0;
          order.suborders.forEach((aSubOrder)  => {
            const comName = aSubOrder.committee.name;
            subsTotals[comName] = subsTotals[comName] + parseFloat(aSubOrder.subTotal);
            colTotals[comName] = colTotals[comName] + parseFloat(aSubOrder.subTotal);
          //  if (aSubOrder.state === 5) {
          //    refAmount += aSubOrder + aSubOrder.refundedAmount;
          //  }
          });
          const subKeys = Object.keys(subsTotals);
          committeeColumns.forEach((aComCol) => {
            if (subKeys.includes(aComCol)) {
              anOrder[aComCol] = subsTotals[aComCol];
            } else {
              anOrder[aComCol] = 0;
            }
          });
          // anOrder.Refunded_Amount = refAmount;
          orderData.push(anOrder);
        });
        const totals = {
          Order_Id: '', Order_Date: '', Total_Revenue: colTotals['Total_Revenue'], Refunded_Amount: colTotals['Refunded_Amount'],
          Customer_Name: '', Customer_Email: ''
        };
        const totalsKeys = Object.keys(colTotals);
        committeeColumns.forEach((aComCol) => {
          if (totalsKeys.includes(aComCol)) {
            totals[aComCol] = colTotals[aComCol];
          } else {
            totals[aComCol] = 0;
          }
        });
        orderData.push(totals);
        const cols = [2, 3];
        let index = 6;
        committeeColumns.forEach(aComCol => {
          cols.push(index);
          index++;
        });
        this.excelService.exportAsExcelFile(orderData, 'FullOrderReport', cols);
        this.mySpinner = false;
      });
    });
  }

  generateMerchReport() {
    this.mySpinner = true;
    this.service.getMerchReport().subscribe((res) => {
      this.ngZone.run(() => {
        const orderData = [];
        let fullTotal = 0;
        let quantTotal = 0;
        res.forEach((aSku) => {
          fullTotal = fullTotal + parseFloat(aSku.Cost);
          quantTotal = quantTotal + parseFloat(aSku.quant);
          const newSku = {
            Name: aSku.name, Display_Name: aSku.displayName, SKU: aSku.SKU, Color: aSku.Color, Size: aSku.Size,
            Quantity: parseFloat(aSku.quant), Total_Revenue: parseFloat(aSku.Cost)
          };
          orderData.push(newSku);
        });
        const totals = {
          Name: '', Display_Name: '', SKU: '', Color: '', Size: 'Total:', Quantity: quantTotal, Total_Revenue: fullTotal
        };
        orderData.push(totals);
        this.excelService.exportAsExcelFile(orderData, 'MerchandiseSkuReport', [6]);
        this.mySpinner = false;
      });
    });
  }

  async generateInvoiceReport() {
    this.mySpinner = true;
    const invoiceNum = await this.openQueryModal();
    this.service.getInvoiceInfo(invoiceNum).subscribe((res) => {
      this.ngZone.run(() => {
        const invoiceInfo = res.invoiceInfo;
        const orderData = [];
        let calcCost = 0;
        let refundedAmount = 0;
        let calcRevenue = 0;
        const vendorInvoiceNumber = invoiceInfo.invoiceNumber;
        const vendorInvoiceDate = invoiceInfo.issueDate;
        const vendorInvoiceSubtotal = parseFloat(invoiceInfo.productSubtotal);
        const vendorServiceFee = parseFloat(invoiceInfo.serviceFee);
        const vendorTax = parseFloat(invoiceInfo.tax);
        const vendorFullCost = parseFloat(invoiceInfo.invoiceTotal);
        const paidDate = invoiceInfo.datePaid;
        const revenue = parseFloat(invoiceInfo.revenueTotal);

        const listOfOrders = invoiceInfo.orders;
        listOfOrders.forEach((order) => {
          let anOrderTotal = 0;
          const thisOrderDetails = {
            Order_number: order.id, Products: '', Quantity: '',
            Unit_Cost: '', Total_Cost: '', Order_total: ''
          };
          orderData.push(thisOrderDetails);
          refundedAmount += order['Refunded_Amount'];
          // loop through suborders
          order.suborders.forEach((suborder) => {
            const subCommitteeId = suborder.committee.id;
            if ([26, 42, 44, 60].includes(subCommitteeId)) {
              calcRevenue += parseFloat(suborder.subTotal);
              suborder.orderitems.forEach((anItem) => {
                const extraCost = this.calculateItemExtraCost(anItem);
                const itemCost = anItem.quantity * (parseFloat(anItem.product_variant.unitCost) + extraCost);
                calcCost += itemCost;
                anOrderTotal += itemCost;
                const thisOrderItemDetails = {
                  Order_number: '', Products: anItem.product.name + ' - ' + anItem.product_variant.displayName, Quantity: anItem.quantity,
                  Unit_Cost: parseFloat(anItem.product_variant.unitCost) + extraCost,
                  Total_Cost: itemCost, Order_total: ''
                };
                orderData.push(thisOrderItemDetails);
              });
            }
          });
          const thisOrderTotals = {
            Order_number: '', Products: '', Quantity: '',
            Unit_Cost: '', Total_Cost: '', Order_total: anOrderTotal
          };
          orderData.push(thisOrderTotals);
        });
        const emptyLine = {
          Order_number: '', Products: '', Quantity: '', Unit_Cost: '', Total_Cost: '', Order_total: ''
        };
        orderData.push(emptyLine);
        const headerLine = {
          Order_number: '', Products: '', Quantity: '', Unit_Cost: 'Value from Invoice', Total_Cost: 'Calculated Value', Order_total: ''
        };
        orderData.push(headerLine);
        const calcTax = calcCost * 0.059968;
        const totals = {
          Order_number: '', Products: '', Quantity: 'Taxes:',
          Unit_Cost: vendorTax, Total_Cost: calcTax, Order_total: ''
        };
        orderData.push(totals);
        const calcFullCost = calcCost + calcTax;
        const calcServiceFee = calcFullCost * 0.03;
        const totals2 = {
          Order_number: '', Products: '', Quantity: 'Service Fee:',
          Unit_Cost: vendorServiceFee, Total_Cost: calcServiceFee, Order_total: ''
        };
        orderData.push(totals2);
        const totals3 = {
          Order_number: '', Products: '', Quantity: 'Subtotal:',
          Unit_Cost: vendorInvoiceSubtotal, Total_Cost: calcCost, Order_total: ''
        };
        orderData.push(totals3);
        const calcSubtotal = calcCost + calcServiceFee;
        const calcTotal = calcSubtotal + calcTax;
        const totals4 = {
          Order_number: '', Products: '', Quantity: 'Full Cost:',
          Unit_Cost: vendorFullCost, Total_Cost: calcTotal, Order_total: ''
        };
        orderData.push(totals4);
        const totals5 = {
          Order_number: '', Products: '', Quantity: 'Revenue',
          Unit_Cost: '', Total_Cost: calcRevenue, Order_total: ''
        };
        orderData.push(totals5);
        const totals6 = {
          Order_number: '', Products: '', Quantity: 'Profit',
          Unit_Cost: '', Total_Cost: calcRevenue - calcTotal, Order_total: ''
        };
        orderData.push(totals6);
        const emptyLine2 = {
          Order_number: '', Products: '', Quantity: '', Unit_Cost: '', Total_Cost: '', Order_total: ''
        };
        orderData.push(emptyLine2);
        const orderDetails = {
          Order_number: 'Vendor invoice #' + vendorInvoiceNumber, Products: '', Quantity: '',
          Unit_Cost: '', Total_Cost: '', Order_total: ''
        };
        orderData.push(orderDetails);
        const orderDetails2 = {
          Order_number: 'Vendor invoice date: ' + this.dtToFormatPipe.transform(this.dtToLocalPipe.transform(
            this.fromMSPipe.transform(vendorInvoiceDate)), 'LLLL d, yyyy'), Products: '', Quantity: '',
          Unit_Cost: '', Total_Cost: '', Order_total: ''
        };
        orderData.push(orderDetails2);
        this.excelService.exportAsExcelFile(orderData, 'InvoiceReport', [3,4,5]);
        this.mySpinner = false;
      });
    });
  }

  calculateItemExtraCost(anItem) {
    let variantExtra = 0.0;
    const curtime = new Date().getTime();
    anItem.product_variant.cost_variants.forEach((aCostVariant) => {
      if (((aCostVariant.endDate === null) || (aCostVariant.endDate > curtime)) && (curtime >= aCostVariant.startDate)) {
        variantExtra = parseFloat(aCostVariant.costVariant);
      }
    });

    return variantExtra;
  }
  async openQueryModal(): Promise<string> {
    const dialogRef = this.dialog.open(QueryModalComponent, {
      width: '400px'
    });
    const instance = dialogRef.componentInstance;
    instance.prompt = 'Enter Invoice #:';
    return await dialogRef.afterClosed().toPromise();
  }

  gotoOrder(index) {
    // do nothing for now
    this.router.navigateByUrl('order-detail/' + this.unshippedOrdersArray[index].id + '?fromUnshipped=true');
  }
}
