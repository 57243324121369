import {Component, NgZone, OnInit} from '@angular/core';
import {Order, OrderItem, SubOrder} from '../models/orders.model';
import {UserState} from '../store/reducers/user.reducer';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../services/api.service';
import {OrdersService} from '../services/orders.service';
import {Store} from '@ngrx/store';
import {getCurrentUser} from '../store/reducers';
import * as moment from 'moment-timezone';
import {environment} from '../../environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-suborder-detail',
  templateUrl: './suborder-detail.component.html',
  styleUrls: ['./suborder-detail.component.css']
})
export class SuborderDetailComponent implements OnInit {
  public pageTitle = 'Order Details';
  thisOrder: Order;
  objectKeys = Object.keys;
  currentUser: UserState = {
    user: {myId: -1, email: '', first: '', last: '' , committeeId: '(0)'},
    authenticated: false,
    token: ''};
  private sub: any;
  orderId: number;
  mySpinner: boolean;
  workingSub: SubOrder;
  modalRef: BsModalRef;
  orderReadableDate = '';
  mySuborders: SubOrder[] = [];
  myCommittees: number[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private http: HttpClient,
    private api: ApiService,
    private modalService: BsModalService,
    private service: OrdersService,
    private store: Store<UserState>
  ) {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
      this.myCommittees = this.stringToArray(this.currentUser.user.committeeId);
    });

  }

  stringToArray(input: string) {
    return input.slice(1, -1).split(',').map(Number);
  }
  ngOnInit() {
    this.sub = this.route.paramMap.subscribe(params => {
      this.ngZone.run(() => {
        this.thisOrder = {suborders: [], customer: {firstName: '', lastName: '', addressLine1: '', addressLine2: '',
            city: '', stateOrProvince: '', zipOrPostalCode: '', waId: 0},
          shipping: {name: '', addressLine1: '', city: '', country: '', state: '', zip: ''}};
        this.orderId = +params.get('orderId'); // (+) converts string 'id' to a number
        this.mySpinner = true;
        this.service.getAnOrder(this.orderId).subscribe((res) => {
          this.ngZone.run(() => {
            this.thisOrder = res;
            if (res.shipping === null) {
              this.thisOrder.shipping = {name: '', addressLine1: '', city: '', country: '', state: '', zip: ''};
            }
            const orderNumeric = this.thisOrder.orderDate;
            this.orderReadableDate = moment(orderNumeric).format('LLL');
            this.thisOrder.suborders.forEach((aSub) => {
              if (this.myCommittees.includes(aSub.committee.id)) {
                this.mySuborders.push(aSub);
                aSub.orderitems.forEach((aOI) => {
                  aOI.showDataPoints = false;
                  aOI.parsedDataPoints = JSON.parse(aOI.dataPoints);
                  aOI.showDataPoints = aOI.parsedDataPoints.toString() !== '{}';
                });
              }
            });
            this.mySpinner = false;
          });
        });
      });
    });
  }

  trackByIndexSubOrder(index, item): any {
    return index;
  }

  goback() {
    this.router.navigate(['admin'], { queryParams: {path: 'suborders'}});
  }

  getDropdownId(index) {
    return 'dropdownmenu' + index;
  }

  getProductName(item: OrderItem) {
    let varString = '';
    if (item.product_variant !== null) {
      varString = ' - ' + item.product_variant.displayName;
    }
    return item.product.name + varString;
  }

  getProductOptionName(item: OrderItem) {
    if (item.product_variant != null) {
      if (item.product_variant.size != null) {
        return 'Size: ' + item.product_variant.size.name;
      } else if (item.product_variant.optionName != null) {
        return 'Option: ' + item.product_variant.optionName;
      } else {
        return 'No option selected';
      }
    } else {
      return 'No option selected';
    }
  }

  getSuborderStatus(aSuborderState) {
    let stateStr = 'Shopping Cart';
    switch (aSuborderState) {
      case 0:
      default:
        break;
      case 1:
        stateStr = 'Submitted';
        break;
      case 2:
        stateStr = 'In Production';
        break;
      case 3:
        stateStr = 'Shipped';
        break;
      case 4:
        stateStr = 'Complete';
        break;
      case 5:
        stateStr = 'Canceled/Refunded';
        break;
    }
    return stateStr;
  }

  getOrderStatus(thisOrderstate) {
    let stateStr = 'Shopping Cart';
    switch (thisOrderstate) {
      case 0:
      default:
        break;
      case 1:
        stateStr = 'Submitted';
        break;
      case 2:
        stateStr = 'In Production';
        break;
      case 3:
        stateStr = 'Shipped';
        break;
      case 4:
        stateStr = 'Complete';
        break;
      case 5:
        stateStr = 'Canceled/Refunded';
        break;
    }
    return stateStr;
  }

  cancelOrder() {
  }

  refundOrder() {
  }

  markOrderShipped() {
    this.service.shipOrder(this.thisOrder).subscribe((res) => {
      this.ngZone.run(() => {
        this.thisOrder = res;
        this.mySuborders.forEach((aSub) => {
          aSub.orderitems.forEach((aOI) => {
            aOI.parsedDataPoints = JSON.parse(aOI.dataPoints);
          });
        });
      });
    });
  }

  cancelSuborder(index) {
  }

  refundSuborder(index) {
  }

  markSuborderShipped(index, template) {
    this.workingSub = this.mySuborders[index];
    this.workingSub.trackingNumbers = '';
    this.modalRef = this.modalService.show(template);
  }

  markSuborderShippedForReal() {
    this.workingSub.trackingNumbers = this.workingSub.trackingNumbers.replace('/^([a-zA-Z0-9, _-]+)$/', ' ');
    this.workingSub.state = 3;
    this.service.shipSuborder(this.workingSub).subscribe((res) => {
      this.ngZone.run(() => {
        this.workingSub = res;
        this.modalRef.hide();
      });
    });
  }
  exportSuborderData(index) {
    this.workingSub = this.mySuborders[index];
    this.http.get('https://scripts.ncanewfs.org/specialty/OrdersExportScript.php?orderId=' + this.thisOrder.id +
      '&commId=(' + this.workingSub.committee.id + ')&toField=' + this.currentUser.user.email + '&token='
      + this.api.authToken + '&mode=' + environment.production ? 'true' : 'false',
      {observe: 'response', responseType: 'blob'})
      .subscribe((response) => {
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        const contentTypeHeader: string = response.headers.get('Content-Type');
        const parts: string[] = contentDispositionHeader.split(';');
        const filename = parts[1].split('=')[1].replace(/['"]+/g, '');
        const blob = new Blob([response.body], { type: contentTypeHeader });
        saveAs(blob, filename);

      });
    alert('Your download will begin shortly.  Please wait for the export file to be generated.');
  }
  exportOrderData() {
    this.http.get('https://scripts.ncanewfs.org/specialty/OrdersExportScript.php?orderId=' + this.thisOrder.id +
      '&commId=()&toField=' + this.currentUser.user.email + '&token=' + this.api.authToken +
      '&mode=' + environment.production ? 'true' : 'false',
      {observe: 'response', responseType: 'blob'})
      .subscribe((response) => {
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        const contentTypeHeader: string = response.headers.get('Content-Type');
        const parts: string[] = contentDispositionHeader.split(';');
        const filename = parts[1].split('=')[1].replace(/['"]+/g, '');
        const blob = new Blob([response.body], { type: contentTypeHeader});
        saveAs(blob, filename);

      });
    alert('Your download will begin shortly.  Please wait for the export file to be generated.');
  }
  printPackingList(index, templatePrint) {
    this.workingSub = this.mySuborders[index];
    const subId = this.workingSub.id;
    // this.router.navigateByUrl('(print:print/slip/' + subId);
    this.router.navigate(['/',
      { outlets: {
          'print': ['print', 'slip', subId]
        }}]);
    setTimeout(() => {
      this.modalRef = this.modalService.show(templatePrint);
    }, 1000);
  }

  itemIsObject(inObject) {
    const isAnObject = inObject !== null && inObject.constructor === Object;
    return isAnObject;
  }
  itemIsArray(inObject) {
    const isAnArray = inObject !== null && inObject.constructor === Array;
    return isAnArray;
  }
  getImagePath(path) {
    if (path.startsWith('Nat')) {
      return 'https://images.ncanewfs.org/blob?filePath=' + path;
    } else {
      return environment.siteImagesUrl + path;
    }
  }}
