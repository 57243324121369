<div class="container-fluid">
  <div *ngIf="loggedIn">
  </div>
  <div *ngIf="!loggedIn" class="card card-container admin-card-container">
    <img id="profile-img" class="profile-img-card" src="assets/avatar_2x.png" />
    <p id="profile-name" class="profile-name-card"></p>
    <form [formGroup]="loginFormGroup" class="form-signin">
      <span id="reauth-email" class="reauth-email"></span>
      <input formControlName="emailAddress" type="email" id="username" class="form-control" placeholder="Email address" required autofocus>
      <input formControlName="password" type="password" id="password" class="form-control" placeholder="Password" required>
      <p class="text-danger" *ngIf="signInInvalid">Please enter valid login credentials</p>
      <p class="text-danger" *ngIf="signInFailed">Your signin attempt failed or your account is not allowed to administer tests.  Please try again.</p>
      <div class="position-relative text-center">
        <button (click)="doLogin()" class="btn btn-lg btn-info btn-block btn-signin" type="button" id="login-submit">Sign in</button>
        <div *ngIf="loggingIn" class="logging-spinner"></div>
      </div>
    </form><!-- /form -->

    <a href="https://members.ncanewfs.org/Sys/ResetPasswordRequest" class="forgot-password">
      Forgot your password or need to create one for the first time?
    </a>

  </div>
</div>
