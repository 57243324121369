import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrdersService} from '../services/orders.service';
import {select, Store} from '@ngrx/store';
import {getCurrentUser} from '../store/reducers';
import {getUsers, UserState} from '../store/reducers/user.reducer';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { saveAs } from 'file-saver';
import {ApiService} from '../services/api.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-suborders-help',
  templateUrl: './suborders-help.component.html',
  styleUrls: ['./suborders-help.component.css']
})
export class SubordersHelpComponent implements OnInit {

  currentUser: UserState = {
      user: { myId: -1, email: '', first: '', last: '' },
      authenticated: false,
      token: ''};

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ngZone: NgZone,
              private http: HttpClient,
              private api: ApiService,
              private store: Store<UserState>) {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
    });
  }

  ngOnInit() {
  }

  exportAll() {
    const isTestMode = environment.production ? 'false' : 'true';
    const currentEvent = environment.currentEventId;
    this.http.get('https://scripts.ncanewfs.org/specialty/OrdersExportScript.php?prodOnly=true&eventId=' + currentEvent +
      '&orderId=-1&commId=' + this.currentUser.user.committeeId + '&toField=' + this.currentUser.user.email + '&token=' +
      this.api.authToken + '&mode=' + isTestMode,
      {observe: 'response', responseType: 'blob'})
        .subscribe((response) => {
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
          const contentTypeHeader: string = response.headers.get('Content-Type');
          const parts: string[] = contentDispositionHeader.split(';');
          const filename = parts[1].split('=')[1].replace(/['"]+/g, '');
          const blob = new Blob([response.body], { type: contentTypeHeader });
        saveAs(blob, filename);

    });
    alert('Please check your email for the export file that was generated.');
  }

}
