import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {NatSpecAddVariantBody, NatSpecProductVariant, Product} from '../models/products.model';

@Injectable()
export class ProductsService {
  constructor(private _api: ApiService,
              private _http: HttpClient) {}

  public getProductTable(body: any): Observable<any> {
    return this._http.get('assets/data/productTableData.json');
  }

  public getProductCategories(): Observable<any> {
    return this._api.get('/productCategories');
  }

  public getProducts(catId: number): Observable<any> {
    let path = '/products?event=1';
    if (catId > 0) {
      path += '&category=' + catId;
    }

    return this._api.get(path);
  }

  public getProduct(prodId: number): Observable<any> {
    return this._api.get('/products/' + prodId);
  }

  public getProductVariants(prodId: number): Observable<any> {
    return this._api.get('/productVariants?productId=' + prodId);
  }

  public updateProductVariantInventory(body: NatSpecProductVariant, variantId: number): Observable<any> {
    return this._api.put('/productVariants/' + variantId, body);
  }

  public addVariants(body: NatSpecAddVariantBody, prodId: number): Observable<any> {
    return this._api.post('/products/' + prodId, body);
  }

  public getProductImages(prodId: number): Observable<any> {
    return this._api.get('/products/' + prodId + '/images');
  }
}
