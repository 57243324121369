import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import { ApiService } from '../services/api.service';
import {modes} from '../models/modes.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationData} from '../shared/validationMessages.component';
import {NatSpecCommittee} from '../models/committee.model';
import {CommitteesService} from '../services/committees.service';

@Component({
  selector: 'app-manage-committees',
  templateUrl: './manage-committees.component.html',
  styleUrls: ['./manage-committees.component.css']
})
export class ManageCommitteesComponent implements OnInit, AfterViewInit {


  committees: NatSpecCommittee[];
  mySpinner: boolean;
  mode: number;
  selectedCommittee: NatSpecCommittee;
  committeeForm: FormGroup;

  validationData: ValidationData = {
    'committeeName': [
      {type: 'required', message: 'Please enter a name for this committee'}
    ],
    'committeeContactName': [
      {type: 'required', message: 'Please enter the contact name for this committee'}
    ],
    'committeeContactEmail': [
      {type: 'required', message: 'Please enter the contact email address for this committee'}
    ],
    'committeeWaId': [
      {type: 'required', message: 'Please enter the Wild Apricot Id for the contact of this committee'}
    ]
  };

  constructor(private api: ApiService,
              private service: CommitteesService,
              private formBuilder: FormBuilder,
              private ngZone: NgZone) {
    this.committeeForm = this.formBuilder.group({
      committeeName: ['', Validators.required],
      committeeContactName: ['', Validators.required],
      committeeContactEmail: ['', Validators.required],
      committeeWaId: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.mode = modes.TABLEVIEW;
    this.selectedCommittee = null;
  }

  ngAfterViewInit(): void {
    this.refreshCommittees();
  }

  refreshCommittees() {
    this.mode = modes.TABLEVIEW;
    setTimeout(() => {
      this.mySpinner = true;
      this.service.getCommittees().subscribe((res) => {
        this.ngZone.run(() => {
          this.committees = res;
          this.mySpinner = false;
        });
      });
    }, 500);
  }
  addNewCommittee() {
    this.selectedCommittee = {id: -1, name: '', contactName: '', contactEmail: '', waId: -1};
    const controls = this.committeeForm.controls;
    const committee = this.selectedCommittee;
    controls.committeeName.setValue(committee.name);
    controls.committeeContactName.setValue(committee.contactName);
    controls.committeeContactEmail.setValue(committee.contactEmail);
    controls.committeeWaId.setValue(committee.waId);

    this.mode = modes.EDITVIEW;
  }
  editCommittee() {
    const controls = this.committeeForm.controls;
    const committee = this.selectedCommittee;
    controls.committeeName.setValue(committee.name);
    controls.committeeContactName.setValue(committee.contactName);
    controls.committeeContactEmail.setValue(committee.contactEmail);
    controls.committeeWaId.setValue(committee.waId);

    this.mode = modes.EDITVIEW;
  }
  saveCommittee() {
    this.committeeForm.updateValueAndValidity();
    if (!this.committeeForm.valid) {
      return;
    }
    this.selectedCommittee.name = this.committeeForm.controls.committeeName.value;
    this.selectedCommittee.contactName = this.committeeForm.controls.committeeContactName.value;
    this.selectedCommittee.contactEmail = this.committeeForm.controls.committeeContactEmail.value;
    this.selectedCommittee.waId = this.committeeForm.controls.committeeWaId.value;

    if (this.selectedCommittee.id === -1) {
      this.service.newCommittee(this.selectedCommittee)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshCommittees();
          });
        });
    } else {
      this.service.updateCommittee(this.selectedCommittee, this.selectedCommittee.id)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshCommittees();
          });
        });
    }
  }

  committeeDetails(index) {
    this.selectedCommittee = this.committees[index];
    this.mode = modes.DETAILVIEW;
  }

  isTableViewMode() {
    return this.mode === modes.TABLEVIEW;
  }
  isDetailViewMode() {
    return this.mode === modes.DETAILVIEW;
  }
  isEditViewMode() {
    return this.mode === modes.EDITVIEW;
  }
}
