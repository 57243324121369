import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiService} from '../../services/api.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as userActions from '../actions/user.actions';
import {switchMap} from 'rxjs/operators';


@Injectable()
export class UserEffects {
  constructor(private actions$: Actions,
              private api: ApiService) {
  }

/*
  @Effect()
  loginUser$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UserActionTypes.LoginUser),
    switchMap(() => {
      return this.api.post('/members/validate', {email: em, password: pw, isAdmin: true})
        .pipe(
          map((resp) => {
            return new userActions.LoginUserAction(resp);
          })
        );
    })
  );
*/
}
