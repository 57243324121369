import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {
  public pageTitle = 'Shipping Management';

  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoOrders() {
    this.router.navigateByUrl('orders');
  }

  gotoProducts() {
    this.router.navigateByUrl('products');
  }

  gotoAdmin() {
    this.router.navigateByUrl('admin');
  }

  logout() {
    this.router.navigateByUrl('');
  }
}
