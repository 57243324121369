<div class="row">
  <div class="col-md-12">
    <div *ngIf="isTableViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Complete list of All events in the system</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="addNewEvent()" type="button">Add Event</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
          <table class="table table-hover table-bordered display pl-2 pr-2 py-0 w-100"
                 style="cursor: pointer;">
            <tr>
              <th>Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Is Open</th>
            </tr>
            <tr *ngFor="let anEvent of events;let index = index;" (click)="eventDetails(index)">
              <td>{{ anEvent.name }}</td>
              <td>{{ anEvent.startDate }}</td>
              <td>{{ anEvent.endDate }}</td>
              <td>{{ anEvent.open ? 'true' : 'false'}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="isDetailViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Event Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="editEvent()" type="button">Edit Event</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card w-50 mt-1">
            <div class="card-body">
              <table class="table table-borderless table-sm table-tight ml-2">
                <tr>
                  <td width="25%">Name:</td><td width="75%">{{ selectedEvent.name }}</td>
                </tr>
                <tr>
                  <td>Start Date:</td><td>{{ getStartDate() }}</td>
                </tr>
                <tr>
                  <td>End Date:</td><td>{{ getEndDate() }}</td>
                </tr>
                <tr>
                  <td>Is Open?:</td><td>{{ selectedEvent.open ? 'true' : 'false' }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isEditViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Edit Event Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="saveEvent()" type="button">Save Event</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="card w-50 mt-1">
                <div class="card-body">
                  <form [formGroup]="eventForm">
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Event Name" formControlName="eventName">
                      <app-validation-messages [formGroup]="eventForm" [data]="validationData" name="eventName"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput [matDatepicker]="picker1" placeholder="Start Date of Event (mm/dd/yyyy)" formControlName="eventStartDate">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                      <app-validation-messages [formGroup]="eventForm" [data]="validationData" name="eventStartDate"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 79%">
                      <input matInput [matDatepicker]="picker2" placeholder="End Date of Event (mm/dd/yyyy)" formControlName="eventEndDate">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                      <app-validation-messages [formGroup]="eventForm" [data]="validationData" name="eventEndDate"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 49%">
                      <select matNativeControl placeholder="Open" formControlName="eventIsOpen">
                        <option></option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                      <app-validation-messages [formGroup]="eventForm" [data]="validationData" name="eventIsOpen"></app-validation-messages>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
