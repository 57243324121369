import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NatSpecEvent} from '../models/event.model';

@Injectable()
export class EventsService {
  constructor(private _api: ApiService,
              private _http: HttpClient) {
  }

  public getEvents(): Observable<any> {
    return this._api.get('/events');
  }
  public updateEvent(body: NatSpecEvent, eventId: number): Observable<any> {
    return this._api.put('/events/' + eventId, body);
  }
  public newEvent(body: any): Observable<any> {
    return this._api.post('/events', body);
  }

}
