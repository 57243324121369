import {Component, NgZone, OnInit} from '@angular/core';
import {Order, OrderItem, SubOrder} from '../models/orders.model';
import {UserState} from '../store/reducers/user.reducer';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../services/api.service';
import {OrdersService} from '../services/orders.service';
import {Store} from '@ngrx/store';
import {getCurrentUser} from '../store/reducers';
import * as moment from 'moment-timezone';
import {Form, FormList} from '../models/forms.model';
import {FormsService} from '../services/forms.service';

@Component({
  selector: 'app-forms-detail',
  templateUrl: './forms-detail.component.html',
  styleUrls: ['./forms-detail.component.css']
})
export class FormsDetailComponent implements OnInit {
  public pageTitle = 'Form Details';
  thisForm: Form;
  objectKeys = Object.keys;
  theData: any;
  currentUser: UserState = {
    user: {myId: -1, email: '', first: '', last: '' , committeeId: '(0)'},
    authenticated: false,
    token: ''};
  private sub: any;
  formId: number;
  mySpinner: boolean;
  submitReadableDate = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private http: HttpClient,
    private api: ApiService,
    private service: FormsService,
    private store: Store<UserState>
  ) {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
    });

  }

  ngOnInit() {
    this.sub = this.route.paramMap.subscribe(params => {
      this.ngZone.run(() => {
        this.thisForm = {committeeId: 0, submitDate: new Date(), formData: '', eventId: 1, id: 1};
        this.formId = +params.get('formId'); // (+) converts string 'id' to a number
        this.mySpinner = true;
        this.service.getAForm(this.formId).subscribe((res) => {
          this.ngZone.run(() => {
            this.thisForm = res;
            const dateNumeric = this.thisForm.submitDate;
            this.submitReadableDate = moment(dateNumeric).format('LLL');
            this.mySpinner = false;
          });
        });
      });
    });
  }

  trackByIndexSubOrder(index, item): any {
    return index;
  }

  goback() {
    this.router.navigate(['admin'], { queryParams: {path: 'forms'}});
  }

  getDropdownId(index) {
    return 'dropdownmenu' + index;
  }
}
