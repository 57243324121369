import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Order, SubOrder} from '../models/orders.model';
import {FormList} from '../models/forms.model';

@Injectable()
export class FormsService {
  constructor(private _api: ApiService,
              private _http: HttpClient) {}

  public getAForm(formId: number): Observable<FormList> {
    return this._api.get('/forms/' + formId);
  }
  public getFormsTable(body: any, committeeId: string): Observable<any> {
    return this._api.post('/forms/table?comId=' + committeeId, body);
  }
}
