import { Action } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';

export enum UserActionTypes {
  LoginUser = 'Login a User',
  LogoutUser = 'Logout a User'
}

export class LoginUserAction implements Action {
  readonly type = UserActionTypes.LoginUser;
  constructor(public payload: UserState) {}
}

export class LogoutUserAction implements Action {
  readonly type = UserActionTypes.LogoutUser;
}
export type UserActions = LoginUserAction | LogoutUserAction;
