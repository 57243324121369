import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cat-card',
  templateUrl: './cat-card.component.html',
  styleUrls: ['./cat-card.component.scss']
})
export class CatCardComponent implements OnInit {
  @Input() item: any;

  constructor() { }

  ngOnInit() {
  }

  getIconToDisplay() {
    if ((this.item.icon_path === null) || (this.item.icon_path === '')) {
      return 'assets/images/product/icon.png';
    } else {
      return 'assets/images/product/' + this.item.icon_path;
    }
  }

}
