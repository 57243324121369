import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BsModalService} from 'ngx-bootstrap/modal';
import {OrdersService} from '../services/orders.service';
import { saveAs } from 'file-saver';
import {Order} from '../models/orders.model';
import {ExcelService} from '../services/excel.service';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-orders-help',
  templateUrl: './orders-help.component.html',
  styleUrls: ['./orders-help.component.css']
})
export class OrdersHelpComponent implements OnInit {

  mySpinner: boolean;
  allOrders: Order[];
  orderData: any[];

  constructor(    private router: Router,
                  private cp: CurrencyPipe,
                  private ngZone: NgZone,
                  private http: HttpClient,
                  private modalService: BsModalService,
                  private excelService: ExcelService,
                  private service: OrdersService) { }

  ngOnInit() {
    this.mySpinner = false;
    this.orderData = [];
  }

  exportAll() {
    this.mySpinner = true;
    this.service.getAllOrders(2).subscribe((res) => {
      this.ngZone.run(() => {
        this.allOrders = res;
        this.orderData = [];
        this.allOrders.forEach((aOrder) => {
          let newOrder = {};
          const orderId = aOrder.id;
          const customerEmail = aOrder.customer.email;
          const customer = aOrder.customer.firstName + ' ' + aOrder.customer.lastName;
          const orderTotal = aOrder.totalCost;
          let refundAmount = 0.0;
          let orderString = '';
          aOrder.suborders.forEach((aSub) => {
            aSub.orderitems.forEach((aOI) => {
              let basePrice = parseFloat(aOI.product.basePrice);
              let displayName = aOI.product.name;
              if (aOI.product_variant !== null) {
                basePrice += parseFloat(aOI.product_variant.costVariant);
                displayName += ':' + aOI.product_variant.displayName;
              }
              const fixedProdAmount = aOI.quantity * basePrice;
              const productAmount = this.cp.transform(aOI.quantity * basePrice);
              orderString += 'Product: ' + displayName + ', Quantity: ' + aOI.quantity;
              const priceString = ', Cost: ' + productAmount;
              // check if the product can be refunded - NOTE: this year we're not doing uniform trophies
              const productSku = aOI.product.baseSkuNumber;
              if (((productSku < 300000) && (productSku !== 128000)) ||    // reservations
                (productSku === 400000) || (productSku === 400200) ||   // program ads
                (productSku === 400010) ||    // top 20 tickets
                (productSku === 400210) || (productSku === 480000)) {   // sponsors
                refundAmount += fixedProdAmount;
                orderString += priceString + ' - Eligible for a refund';
              } else {
                orderString += ' - Not eligible for a refund';
              }
              orderString += "\n";
            });
          });
          if (orderString.length > 0) {
            orderString = orderString.substring(0, orderString.length -1);
          }
          if (refundAmount > 0.0) {
            newOrder = {
              orderId: orderId, customer: customer, email: customerEmail, orderTotal: orderTotal,
              refundAmount: refundAmount.toFixed(2), body: orderString
            };
            this.orderData.push(newOrder);
          }
        });
        this.excelService.exportAsExcelFile(this.orderData, 'RefundSheet', [3, 4]);
        this.mySpinner = false;
      });
    });

  }

}
