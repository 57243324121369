import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {HeaderComponent} from './header/header.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild(HeaderComponent, { static: true }) header: HeaderComponent;
  title = 'ncanationaladmin';

  constructor(private router: Router) { }

  public onRouterOutletActivate(event : any) {
    this.header.pageTitle = event.pageTitle;
  }

}
