import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import { NatSpecEvent } from '../models/event.model';
import { ApiService } from '../services/api.service';
import {EventsService} from '../services/events.service';
import {modes} from '../models/modes.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationData} from '../shared/validationMessages.component';
import {mod} from 'ngx-bootstrap/chronos/utils';
import * as Dates from '../shared/dates';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-manage-events',
  templateUrl: './manage-events.component.html',
  styleUrls: ['./manage-events.component.css']
})

export class ManageEventsComponent implements OnInit, AfterViewInit {

  events: NatSpecEvent[];
  mySpinner: boolean;
  mode: number;
  selectedEvent: NatSpecEvent;
  eventForm: FormGroup;

  validationData: ValidationData = {
    'eventName': [
      {type: 'required', message: 'Please enter a name for this event'}
    ],
    'eventStartDate': [
      {type: 'required', message: 'Please enter a start date for this event'}
    ],
    'eventEndDate': [
      {type: 'required', message: 'Please enter an end date for this event'}
    ]
  };

  constructor(private api: ApiService,
              private service: EventsService,
              private formBuilder: FormBuilder,
              private ngZone: NgZone) {
    this.eventForm = this.formBuilder.group({
      eventName: ['', Validators.required],
      eventStartDate: ['', Validators.required],
      eventEndDate: ['', Validators.required],
      eventIsOpen: [false]
    });
  }

  ngOnInit(): void {
    this.mode = modes.TABLEVIEW;
    this.selectedEvent = null;
  }

  ngAfterViewInit(): void {
    this.refreshEvents();
  }

  refreshEvents() {
    this.mode = modes.TABLEVIEW;
    setTimeout(() => {
      this.mySpinner = true;
      this.service.getEvents().subscribe((res) => {
        this.ngZone.run(() => {
          this.events = res;
          this.mySpinner = false;
        });
      });
    }, 500);
  }
  addNewEvent() {
    this.selectedEvent = {id: -1, name: '', startDate: '', endDate: '', open: false};
    const controls = this.eventForm.controls;
    const event = this.selectedEvent;
    controls.eventName.setValue(event.name);
    controls.eventStartDate.setValue(Dates.makeDate(event.startDate));
    controls.eventEndDate.setValue(Dates.makeDate(event.endDate));
    controls.eventIsOpen.setValue(event.open ? 'Yes' : 'No');

    this.mode = modes.EDITVIEW;
  }
  editEvent() {
    const controls = this.eventForm.controls;
    const event = this.selectedEvent;
    controls.eventName.setValue(event.name);
    controls.eventStartDate.setValue(Dates.makeDate(event.startDate));
    controls.eventEndDate.setValue(Dates.makeDate(event.endDate));
    controls.eventIsOpen.setValue(event.open ? 'Yes' : 'No');

    this.mode = modes.EDITVIEW;
  }
  saveEvent() {
    this.eventForm.updateValueAndValidity();
    if (!this.eventForm.valid) {
      return;
    }
    this.selectedEvent.name = this.eventForm.controls.eventName.value;
    const startD = this.eventForm.controls['eventStartDate'].value;
    this.selectedEvent.startDate = moment(startD).format('YYYY-MM-DD');
    const endD = this.eventForm.controls['eventEndDate'].value;
    this.selectedEvent.endDate = moment(endD).format('YYYY-MM-DD');
    this.selectedEvent.open = this.eventForm.controls.eventIsOpen.value === 'Yes';
    if (this.selectedEvent.id === -1) {
      this.service.newEvent(this.selectedEvent)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshEvents();
          });
        });
    } else {
      this.service.updateEvent(this.selectedEvent, this.selectedEvent.id)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshEvents();
          });
        });
    }
  }
  getStartDate() {
    const sd = this.selectedEvent.startDate;
    if (sd) {
      const parts = sd?.split('-');
      const old_start_date = new Date(parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10));
      const startDate = new Date(old_start_date);
      return startDate.toDateString();
    } else {
      return new Date().toDateString();
    }
  }
  getEndDate() {
    const ed = this.selectedEvent.endDate;
    if (ed) {
      const parts = ed?.split('-');
      const old_end_date = new Date(parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10));
      const endDate = new Date(old_end_date);
      return endDate.toDateString();
    } else {
      return new Date().toDateString();
    }
  }

  eventDetails(index) {
    this.selectedEvent = this.events[index];
    this.mode = modes.DETAILVIEW;
  }

  isTableViewMode() {
    return this.mode === modes.TABLEVIEW;
  }
  isDetailViewMode() {
    return this.mode === modes.DETAILVIEW;
  }
  isEditViewMode() {
    return this.mode === modes.EDITVIEW;
  }
}
