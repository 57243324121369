import {AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ProductsService} from '../services/products.service';
import { StringFilterPipe } from '../services/StringFilter';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, AfterViewInit {
  public pageTitle = 'Product Management';
  buttonState: any;
  p = 1;
  public filter = '';
  @Input() searchFilterText: string;
  searchResults: any [];
  productCategories: any[];
  mySpinner: boolean;
  cloneProductList;
  firstTimeSort = true;

  constructor(private router: Router,
              private ngZone: NgZone,
              private changeDetect: ChangeDetectorRef,
              private service: ProductsService) { }

  ngOnInit() {
    // initial button states
    this.buttonState = {
      all: true,
      merch: false,
      reserve: false
    };
    this.mySpinner = false;
  }

  FilterAndSetButtonState(value?: string) {
    if (value === 'custom') {
      this.filter = 'custom-' + this.searchFilterText;
    } else {
      this.filter = value;  // update filter
    }
    // set button state
    this.buttonState = {
      all: false,
      merch: false,
      reserve: false
    };
    if (!value) {
      this.buttonState['all'] = true;
      return;
    }
    this.buttonState[value] = true;
  }

  gotoDetails(category) {
    // this.eventService.setSelectedEvent(event);
    this.router.navigateByUrl('/product-category-detail/' + category.id);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mySpinner = true;
      this.service.getProductCategories().subscribe((res) => {
        this.ngZone.run(() => {
          this.productCategories = res;
          if (this.productCategories.length > 0) {
            this.doSort('namea-z');
            if (!this.changeDetect['destroyed']) {
              this.changeDetect.detectChanges();
              this.mySpinner = false;
            }
          }
        });
      });
    }, 500);
  }

  doSort(val) {
    console.log('sort by', val);
    // sort maseter list by val, in place
    if (this.firstTimeSort) {
      // first time we sort by date
      this.productCategories.sort((event1, event2) => {
        if (event1.name > event2.name) {
          return 1;
        }
        if (event1.name < event2.name) {
          return -1;
        }
        return 0;
      });
      this.cloneProductList = this.productCategories.slice();
      this.firstTimeSort = false;
      return;
    }

    if (val === 'namea-z') {
      this.productCategories.sort((event1, event2) => {
        if (event1.name > event2.name) {
          return 1;
        }
        if (event1.name < event2.name) {
          return -1;
        }
        return 0;
      });
    } else if (val === 'namez-a') {
      this.productCategories.sort((event1, event2) => {
        if (event2.name > event1.name) {
          return 1;
        }
        if (event2.name < event1.name) {
          return -1;
        }
        return 0;
      });
    } else { // no sort
      this.productCategories = this.productCategories.slice();
    }
  }

  eventHandler(event) {
    setTimeout(() => {
      this.ngZone.run(() => {
        this.filter = 'custom-' + this.searchFilterText;
        if (!this.changeDetect['destroyed']) {
          this.changeDetect.detectChanges();
        }
      });
    }, 100);
  }
  unfocus(event) {
    setTimeout(() => {
      this.ngZone.run(() => {
        this.filter = '';
        this.searchFilterText = '';
        if (!this.changeDetect['destroyed']) {
          this.changeDetect.detectChanges();
        }
      });
    }, 100);
  }

}
