<div class="row">
  <div class="col-md-12">
    <div *ngIf="isTableViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Complete list of All committees in the system</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="addNewCommittee()" type="button">Add Committee</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
          <table class="table table-hover table-bordered display pl-2 pr-2 py-0 w-100"
                 style="cursor: pointer;">
            <tr>
              <th>Name</th>
              <th>Contact Name</th>
              <th>Contact Email</th>
              <th>Wild Apricot ID</th>
            </tr>
            <tr *ngFor="let aComm of committees;let index = index;" (click)="committeeDetails(index)">
              <td>{{ aComm.name }}</td>
              <td>{{ aComm.contactName }}</td>
              <td>{{ aComm.contactEmail }}</td>
              <td>{{ aComm.waId }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="isDetailViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Committee Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="editCommittee()" type="button">Edit Committee</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card w-50 mt-1">
            <div class="card-body">
              <table class="table table-borderless table-sm table-tight ml-2">
                <tr>
                  <td width="25%">Name:</td><td width="75%">{{ selectedCommittee.name }}</td>
                </tr>
                <tr>
                  <td>Contact Name:</td><td>{{ selectedCommittee.contactName }}</td>
                </tr>
                <tr>
                  <td>Contact Email:</td><td>{{ selectedCommittee.contactEmail }}</td>
                </tr>
                <tr>
                  <td>Wild Apricot Id:</td><td>{{ selectedCommittee.waId }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isEditViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Edit Committee Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="saveCommittee()" type="button">Save Committee</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="card w-50 mt-1">
                <div class="card-body">
                  <form [formGroup]="committeeForm">
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Committee Name" formControlName="committeeName">
                      <app-validation-messages [formGroup]="committeeForm" [data]="validationData" name="committeeName"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Contact Name" formControlName="committeeContactName">
                      <app-validation-messages [formGroup]="committeeForm" [data]="validationData" name="committeeContactName"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Contact Email" formControlName="committeeContactEmail">
                      <app-validation-messages [formGroup]="committeeForm" [data]="validationData" name="committeeContactEmail"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Contact Wild Apricot Id" formControlName="committeeWaId">
                      <app-validation-messages [formGroup]="committeeForm" [data]="validationData" name="committeeWaId"></app-validation-messages>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
