<div class="row mt-2">
  <div class="col-md-12 order-table">
    <h6>Find Orders Instructions</h6>
    <div>
      <p>The table to the right shows ALL of the known orders in the system.  You can use the following controls to narrow the list down so you can find the
        order that you are looking for:</p>
      <ul>
        <li>Click on a Column Header to order the list
          <ul>
            <li>The list is ordered by Order ID initially</li>
            <li>Click on any other Column Header to sort the list by that column</li>
            <li>Click on the same Column Header to reverse the sort order</li>
          </ul>
        </li>
        <li>Enter a Search term
          <ul>
            <li>Type any text into the Search field to filter the list to items that match that text</li>
            <li>Enter an order ID to quickly find that order</li>
            <li>Enter any part of a customer name to find that customer</li>
          </ul>
        </li>
        <li>Use the "Show Entries" popup to change how many orders are shown on a page</li>
        <li>Use the Pagination controls on the bottom right of the table to move between pages of order data</li>
      </ul>
    </div>
    <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
    <button class="btn btn-primary btn-round ml-3" (click)="exportAll()" type="button">Export Refund List</button>
  </div>
</div>
