<div class="row mb-2">
  <div class="col-md-12">
    <button class="btn btn-primary btn-round ml-3" (click)="goback()" type="button">Back to Order List</button>
  </div>
</div>
<div class="row">
  <div class="col-md-10">
    <h3 class="ml-3">Order details for order #{{ thisOrder.id }}</h3>
  </div>
</div>
<div class="row">
  <div class="col-md-10">
    <div class="card w-75 mt-1">
      <div class="card-body">
        <div class="float-left">
          <h5 class="card-title">Order Info</h5>
          <h6 class="card-subtitle mb-2 text-muted">Order Date: {{ orderReadableDate }}</h6>
        </div>
        <div class="float-right">
          <h6 class="card-title">Order Status: {{ getOrderStatus(thisOrder.state) }}</h6>
          <div class="dropdown text-center">
            <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Actions
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item disabled" [routerLink]="" (click)="cancelOrder()">Cancel Order</a>
              <a class="dropdown-item disabled" [routerLink]="" (click)="markOrderShipped()">Mark Shipped</a>
              <a class="dropdown-item disabled" [routerLink]="" (click)="refundOrder()">Refund Order</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="" (click)="exportOrderData()">Export Order Data</a>
            </div>
          </div>
        </div>
        <table class="table table-borderless table-sm table-tight ml-2">
          <tr>
            <td width="25%">Total:</td><td width="75%">{{ thisOrder.totalCost | currency }}</td>
          </tr>
          <tr>
            <td>Refunded Amount:</td><td>{{ thisOrder.refundedAmount | currency }}</td>
          </tr>
          <tr>
            <td>Ship to Customer:</td><td>{{ thisOrder.ship ? 'true' : 'false' }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-10 mx-auto">
    <div class="row d-flex">
      <div class="cust-div mr-4">
        <div class="card mt-1">
          <div class="card-body">
            <h5 class="card-title">Customer Info</h5>
            <h6 class="card-subtitle mb-2 text-muted">Customer Name: {{ thisOrder.customer.firstName }} {{ thisOrder.customer.lastName }}</h6>
            <table class="table table-borderless table-sm table-tight ml-2">
              <tr>
                <td width="35%">Email:</td><td width="65%">{{ thisOrder.customer.email }}</td>
              </tr>
              <tr>
                <td>Phone:</td><td>{{ thisOrder.customer.phone }}</td>
              </tr>
              <tr>
                <td>Address Line 1:</td><td>{{ thisOrder.customer.addressLine1 }}</td>
              </tr>
              <tr>
                <td>Address Line 2:</td><td>{{ thisOrder.customer.addressLine2 }}</td>
              </tr>
              <tr>
                <td>City:</td><td>{{ thisOrder.customer.city }}</td>
              </tr>
              <tr>
                <td>State or Province:</td><td>{{ thisOrder.customer.stateOrProvince }}</td>
              </tr>
              <tr>
                <td>Zip or Postal Code:</td><td>{{ thisOrder.customer.zipOrPostalCode }}</td>
              </tr>
              <tr>
                <td>Country:</td><td>{{ thisOrder.customer.country }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="cust-div mr-4">
        <div class="card mt-1">
          <div class="card-body">
            <h5 class="card-title">Shipping Info</h5>
            <h6 class="card-subtitle mb-2 text-muted">Shipping Name: {{ thisOrder.shipping.name }}</h6>
            <table class="table table-borderless table-sm table-tight ml-2">
              <tr>
                <td>Address Line 1:</td><td>{{ thisOrder.shipping.addressLine1 }}</td>
              </tr>
              <tr>
                <td>City:</td><td>{{ thisOrder.shipping.city }}</td>
              </tr>
              <tr>
                <td>State:</td><td>{{ thisOrder.shipping.state }}</td>
              </tr>
              <tr>
                <td>Zip:</td><td>{{ thisOrder.shipping.zip }}</td>
              </tr>
              <tr>
                <td>Country:</td><td>{{ thisOrder.shipping.country }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<h4 class="ml-5">Suborders</h4>
<div class="row" *ngFor="let aSuborder of thisOrder.suborders;let index = index; trackBy: trackByIndexSubOrder">
  <div class="col-md-10">
    <div class="card w-75 mt-1">
      <div class="card-body">
        <div class="float-left">
          <h5 class="card-title">Suborder ID: {{ aSuborder.id }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">Committee Name: {{ aSuborder.committee.name }}</h6>
          <h6 class="card-subtitle mb-2 text-muted">Committee Contact: {{ aSuborder.committee.contactName }}</h6>
          <h6 class="card-subtitle mb-2 text-muted">Committee Email: {{ aSuborder.committee.contactEmail }}</h6>
          <h6 class="card-subtitle mb-2 text-muted">Suborder Total: {{ aSuborder.subTotal | currency }}</h6>
        </div>
        <div class="float-right">
          <h6 class="card-title">SubOrder Status: {{ getSuborderStatus(aSuborder.state) }}</h6>
          <div class="dropdown text-center">
            <button class="btn btn-info dropdown-toggle" type="button" [id]="getDropdownId(index)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Actions
            </button>
            <div class="dropdown-menu" [attr.aria-labelledby]="getDropdownId(index)">
              <a class="dropdown-item disabled" [routerLink]="" (click)="cancelSuborder(index)">Cancel SubOrder</a>
              <a class="dropdown-item" [routerLink]="" (click)="markSuborderShipped(index, template)">Mark Shipped</a>
              <a class="dropdown-item disabled" [routerLink]="" (click)="refundSuborder(index)">Refund SubOrder</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="" (click)="printPackingList(index, templatePrint)">Print Packing List</a>
              <a class="dropdown-item" [routerLink]="" (click)="exportSuborderData(index)">Export SubOrder Data</a>
            </div>
          </div>
        </div>
        <div class="border-bottom" *ngFor="let item of aSuborder.orderitems">
          <table class="table table-borderless table-sm table-tight ml-2">
            <tr>
              <td width="25%">SKU:</td><td width="75%">{{ item.product.baseSkuNumber + (item.product_variant !== null ? item.product_variant.skuVariant : 0) }}</td>
            </tr>
            <tr>
              <td>Product Name:</td><td>{{ getProductName(item) }}</td>
            </tr>
            <tr>
              <td>Quantity:</td><td>{{ item.quantity }}</td>
            </tr>
            <tr>
              <td>Selected Option:</td><td>{{ getProductOptionName(item) }}</td>
            </tr>
            <tr *ngIf="item.showDataPoints">
              <td colspan="2">
                <table class="table table-bordered table-sm table-tight ml-3">
                  <tr class="border-bottom">
                    <td colspan="3" class="text-center font-weight-bold">Custom Data:</td>
                  </tr>
                  <ng-container *ngFor="let akey of objectKeys(item.parsedDataPoints)" class="border-bottom">
                    <ng-container *ngIf="itemIsObject(item.parsedDataPoints[akey])">
                      <tr>
                        <td colspan="5">{{ akey }}</td>
                      </tr>
                      <ng-container *ngFor="let akey2 of objectKeys(item.parsedDataPoints[akey])">
                        <ng-container *ngIf="itemIsObject(item.parsedDataPoints[akey][akey2])">
                          <tr>
                            <td></td><td colspan="4">{{ akey2 }}</td>
                          </tr>
                          <ng-container *ngFor="let akey3 of objectKeys(item.parsedDataPoints[akey][akey2])">
                            <ng-container *ngIf="itemIsObject(item.parsedDataPoints[akey][akey2][akey3])">
                              <tr>
                                <td colspan="2"></td><td colspan="3">{{ akey3 }}</td>
                              </tr>
                              <ng-container *ngFor="let akey4 of objectKeys(item.parsedDataPoints[akey][akey2][akey3])">
                                <tr>
                                  <td colspan="3"></td><td>{{akey4}}</td><td>{{ item.parsedDataPoints[akey][akey2][akey3][akey4] }}</td>
                                </tr>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="itemIsArray(item.parsedDataPoints[akey][akey2][akey3])">
                              <tr>
                                <td colspan="2"></td><td colspan="3">{{ akey3 }}</td>
                              </tr>
                              <ng-container *ngFor="let subItem of item.parsedDataPoints[akey][akey2][akey3]">
                                <ng-container *ngFor="let item of objectKeys(subItem)">
                                  <tr>
                                    <td colspan="3"></td><td>{{item}}</td><td>{{ subItem[item] }}</td>
                                  </tr>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!itemIsObject(item.parsedDataPoints[akey][akey2][akey3]) && !itemIsArray(item.parsedDataPoints[akey][akey2][akey3])">
                              <tr>
                                <td colspan="2"></td><td>{{ akey3 }}</td><td colspan="2">{{ item.parsedDataPoints[akey][akey2][akey3] }}</td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="itemIsArray(item.parsedDataPoints[akey][akey2])">
                          <tr>
                            <td></td><td colspan="4">{{ akey2 }}</td>
                          </tr>
                          <ng-container *ngFor="let subItem of item.parsedDataPoints[akey][akey2]">
                            <ng-container *ngFor="let item of objectKeys(subItem)">
                              <tr>
                                <td colspan="2"></td><td>{{item}}</td><td colspan="2">{{ subItem[item] }}</td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!itemIsObject(item.parsedDataPoints[akey][akey2]) && !itemIsArray(item.parsedDataPoints[akey][akey2])">
                          <tr>
                            <td></td><td>{{ akey2 }}</td><td colspan="3">{{ item.parsedDataPoints[akey][akey2] }}</td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!itemIsObject(item.parsedDataPoints[akey])">
                      <ng-container *ngIf="akey === 'imagePath'">
                        <tr>
                          <td>{{ akey }}</td><td colspan="4"><a href="{{ getImagePath( item.parsedDataPoints[akey]) }}" download="">Click Here to download</a></td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="akey !== 'imagePath'">
                        <tr>
                          <td>{{ akey }}</td><td colspan="4">{{ item.parsedDataPoints[akey] }}</td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Ship Suborder</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>If you have a tracking number for this shipment, enter it here</p>
    <input type="text" [(ngModel)]="workingSub.trackingNumbers" id="tnums">
  </div>
  <div class="modal-footer">
    <div>
      <button type="button" class="btn btn-secondary pull-right mr-3" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">Cancel</span>
      </button>
      <button type="button" class="btn btn-primary pull-right" aria-label="Close" (click)="markSuborderShippedForReal()">
        <span aria-hidden="true">Ship It</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #templatePrint>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Print Packing Slip for Suborder</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Hit CTL/CMD - P to print the packing slip</p>
  </div>
</ng-template>
