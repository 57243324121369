import {ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {getUsers, UserState} from '../store/reducers/user.reducer';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {getCurrentUser, getUserId} from '../store/reducers/';
import * as fromUser from '../store/reducers/user.reducer';
import {ApiService} from '../services/api.service';
import {OrdersService} from '../services/orders.service';
import {BaseChartDirective} from 'ng2-charts';
import {ITreeOptions, TREE_ACTIONS} from '@circlon/angular-tree-component';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  public pageTitle = 'National Specialty Administration';
  showingPath;
  sub: any;
  currentUser: UserState = {
    user: {myId: -1, email: '', first: '', last: '' },
    authenticated: false,
    token: ''};
  userId$: Observable<number>;
  private chartData: any;
  private perChoice;


  public ordersPerDayLabels = ['loading'];
  public ordersPerDayData = [{data: [0], label: 'loading'}];
  public revPerDayLabels = ['loading'];
  public revPerDayData = [{data: [0], label: 'loading'}];
  public ordersPerDayType = 'line';
  public revPerDayType = 'line';
  public ordersCount = [];
  public ordersPrice = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  public lineChartColors = [{
    borderColor: 'rgba(0,0,240,1)'
  }];
  public lineChartColors2 = [{
    borderColor: 'rgba(0,240,0,1)'
  }];

  nodes = [
    {
      id: 0,
      name: 'Home',
      path: 'admin'
    },
    {
      id: 1,
      name: 'Find an Order',
      path: 'orders'
    },
    {
      id: 2,
      name: 'Find my suborders',
      path: 'suborders'
    },
    {
      id: 3,
      name: 'Forms',
      path: 'forms'
    },
    {
      id: 4,
      name: 'Send SMS Message',
      path: 'sms'
    },
    {
      id: 5,
      name: 'Finance Reports',
      path: 'finance'
    },
    {
      id: 6,
      name: 'System Administration',
      path: 'system',
      children: [
        {
          id: 40,
          name: 'Manage Events',
          path: 'manageevs'
        }, {
          id: 41,
          name: 'Manage Committees',
          path: 'managecomms'
        }, {
          id: 42,
          name: 'Manage Categories',
          path: 'managecats'
        }, {
          id: 43,
          name: 'Manage Colors',
          path: 'managecols'
        }, {
          id: 44,
          name: 'Manage Sizes',
          path: 'managesizes'
        }, {
          id: 45,
          name: 'Manage Products',
          path: 'manageprods'
        }
      ]
    }
  ];
  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          if (node.hasChildren) {
            TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
          }
          TREE_ACTIONS.ACTIVATE(tree, node, $event);
        }
      }
    }
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ngZone: NgZone,
              private service: OrdersService,
              private store: Store<UserState>) {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
    });
    this.perChoice = 'Per Day';
  }

  ngOnInit() {
    this.showingPath = 'admin';
    this.sub = this.route.queryParamMap.subscribe(params => {
      this.ngZone.run(() => {
        const path = params.get('path');
        if (path !== null) {
          this.showingPath = path;
        }
      });
    });
    this.service.getOrdersChart().subscribe(results => {
      this.ngZone.run(() => {
        this.chartData = results;
        this.updateChart();
      });
    });
  }

  buttonClassForOption(buttonName) {
    if (buttonName === this.perChoice) {
      return 'btn btn-primary';
    } else {
      return 'btn btn-info';
    }
  }

  buttonChanged(buttonChoice) {
    this.perChoice = buttonChoice;
    this.updateChart();
  }

  updateChart() {
    const chartLabels = [];

    this.ordersCount = [];
    this.ordersPrice = [];
    // calculate all sums
    const today = moment();
    if (this.perChoice === 'Per Day') {
      const dayWalk = today.subtract(6, 'day');
      let indexCount = 0;
      do {
        const dayKey = dayWalk.format('YYYY-MM-DD');
        chartLabels.push(dayKey);
        const sixdayago = this.chartData.dayData.filter(aDayFound => aDayFound['order_day'] === dayKey);
        if (sixdayago.length > 0) {
          this.ordersCount.push(sixdayago[0]['count']);
          this.ordersPrice.push(sixdayago[0]['order_sum']);
        } else {
          this.ordersCount.push(0);
          this.ordersPrice.push(0);
        }
        dayWalk.add(1, 'day');
        indexCount++;
      } while (indexCount < 7);
      this.ordersPerDayType = 'line';
      this.revPerDayType = 'line';
    } else if (this.perChoice === 'Per Week') {
      const thisWeek = today.week();    // in the range of 1-53
      let weekWalk = thisWeek;
      const thisYear = today.year();
      let workingYear = thisYear;
      if (weekWalk < 7) {
        weekWalk = thisWeek + 46;
        workingYear -= 1;
      } else {
        weekWalk -= 7;
      }
      let indexCount = 0;
      do {
        const finderWeek = workingYear.toString() + weekWalk.toString().padStart(2, '0');
        chartLabels.push(finderWeek);
        const weekAgo = this.chartData.yearWeekData.filter(aWeekFound => aWeekFound['week_number'] === finderWeek);
        if (weekAgo.length > 0) {
          this.ordersCount.push(weekAgo[0]['count']);
          this.ordersPrice.push(weekAgo[0]['order_sum']);
        } else {
          this.ordersCount.push(0);
          this.ordersPrice.push(0);
        }
        weekWalk += 1;
        if (weekWalk === 53) {
          weekWalk = 1;
          workingYear += 1;
        }
        indexCount++;
      } while (indexCount < 7);
      this.ordersPerDayType = 'line';
      this.revPerDayType = 'line';
    } else if (this.perChoice === 'Per Month') {
      const thisMonth = today.month();    // in the range of 0-11
      let monthWalk = thisMonth;
      const thisYear = today.year();
      let workingYear = thisYear;
      if (monthWalk < 6) {
        monthWalk = thisMonth + 7;
        workingYear -= 1;
      } else {
        monthWalk -= 6;
      }
      let indexCount = 0;
      do {
        const finderMonth = workingYear.toString() + '-' + monthWalk.toString().padStart(2, '0');
        chartLabels.push(finderMonth);
        const monthAgo = this.chartData.monthData.filter(aMonthFound => aMonthFound['month_number'] === finderMonth);
        if (monthAgo.length > 0) {
          this.ordersCount.push(monthAgo[0]['count']);
          this.ordersPrice.push(monthAgo[0]['order_sum']);
        } else {
          this.ordersCount.push(0);
          this.ordersPrice.push(0);
        }
        monthWalk += 1;
        if (monthWalk === 13) {
          monthWalk = 1;
          workingYear += 1;
        }
        indexCount++;
      } while (indexCount < 7);
      this.ordersPerDayType = 'line';
      this.revPerDayType = 'line';
    } else {
      // show all time
      this.ordersCount[0] = 532;
      this.ordersPrice[0] = 86943;
      chartLabels.push('2019');
      this.ordersCount[1] = this.chartData.allData[0]['count'];
      this.ordersPrice[1] = this.chartData.allData[0]['order_sum'];
      chartLabels.push('2020');
      this.ordersPerDayType = 'bar';
      this.revPerDayType = 'bar';
    }
    this.ordersPerDayLabels = chartLabels;
    this.revPerDayLabels = chartLabels;
    setTimeout(() => {
      this.ordersPerDayData = [{data: this.ordersCount, label: 'Orders ' + this.perChoice}];
    }, 50);
    setTimeout(() => {
      this.revPerDayData = [{data: this.ordersPrice, label: 'Revenue ' + this.perChoice}];
    }, 100);

  }
  onEvent(anEvent) {
    if (anEvent.eventName === 'activate') {
      const theNode = anEvent.node.data;
      switch (theNode.path) {
        case 'finance':
          this.gotoFinance();
          break;
        case 'sms':
          this.gotoSms();
          break;
        case 'forms':
          this.gotoForms();
          break;
        case 'orders':
          this.gotoOrders();
          break;
        case 'suborders':
          this.gotoSuborders();
          break;
        case 'system':
          this.gotoSystem();
          break;
        case 'manageevs':
          this.gotoManageEvents();
          break;
        case 'managecomms':
          this.gotoManageComms();
          break;
        case 'managecats':
          this.gotoManageCats();
          break;
        case 'managecols':
          this.gotoManageColors();
          break;
        case 'managesizes':
          this.gotoManageSizes();
          break;
        case 'manageprods':
          this.gotoManageProducts();
          break;
        default:
          this.gotoHome();
          break;
      }
    }
  }

  gotoHome() {
    this.showingPath = 'admin';
  }

  gotoOrders() {
    this.showingPath = 'orders';
  }

  gotoSuborders() {
    this.showingPath = 'suborders';
  }

  gotoForms() {
    this.showingPath = 'forms';
  }

  gotoSms() {
    this.showingPath = 'sms';
  }

  gotoFinance() {
    this.showingPath = 'finance';
  }

  gotoSystem() {
    this.showingPath = 'system';
  }

  gotoManageEvents() {
    this.showingPath = 'manageevs';
  }

  gotoManageComms() {
    this.showingPath = 'managecomms';
  }

  gotoManageCats() {
    this.showingPath = 'managecats';
  }

  gotoManageColors() {
    this.showingPath = 'managecols';
  }

  gotoManageSizes() {
    this.showingPath = 'managesizes';
  }

  gotoManageProducts() {
    this.showingPath = 'manageprods';
  }

  gotoShipping() {
    this.router.navigateByUrl('shipping');
  }

  logout() {
    this.router.navigateByUrl('');
  }
}
