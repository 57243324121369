<div class="container-fluid">
  <div class="row bg-light pt-1 filter-sort-section">
    <div class="col-sm-10 d-inline-flex align-items-center">
      <div class="input-group w-25">
        <input type="search" class="form-control" name="searchText" (keydown)="eventHandler($event)"
               id="searchText" [(ngModel)]="searchFilterText" value="{{searchFilterText}}" placeholder="Search Products">
      </div>
    </div>
    <div class="col-sm-2 d-inline-flex align-items-end">
      <p>
        <label class="sort-label" for="">Sort by:</label>
        <select class="custom-select sort-select" #selectElem (change)="doSort(selectElem.value)">
          <option selected value="namea-z">Name A-Z</option>
          <option value="namez-a">Name Z-A</option>
        </select>
      </p>
    </div>
  </div>
  <div class="row bg-light">
    <div class="col-sm-12 mx-auto d-block">
      <!-- <pre>{{ masterList | json}}</pre> -->
      <div class="grid">
        <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
        <div class="prod-cat-card" *ngFor="let category of productCategories | stringFilter: filter " (click)="gotoDetails(category)">
          <app-cat-card [item]="category"></app-cat-card>
        </div>
      </div>
    </div>
  </div>
</div>
