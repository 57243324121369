<div class="row">
  <div class="col-md-12">
    <div>
      <h2>Complete list of All orders containing suborders assigned to you</h2>
      <table datatable [dtOptions]="dtOptions"
             class="table table-hover table-bordered display pl-2 pr-2 py-0 w-100"
             style="cursor: pointer;">
      </table>
    </div>
  </div>
</div>
