import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {
  public headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });
  public authToken: string;

  constructor(
    private _http: HttpClient,
  ) {
    this.authToken = '';
  }

  public get(path: string): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', 'Bearer ' + this.authToken) :
      this.headers;
    return this._http.get(
      `${environment.url}${path}`,
      {headers: authHeaders})
      .pipe(catchError( (err) => this.returnError(err) ));
  }

  public post(path, body): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', 'Bearer ' + this.authToken) :
      this.headers;
    return this._http.post(
      `${environment.url}${path}`,
      JSON.stringify(body),
      {headers: authHeaders}
    );
//      .pipe(catchError( (err) => this.returnError(err)));
  }

  public put(path, body): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', 'Bearer ' + this.authToken) :
      this.headers;
    return this._http.put(
      `${environment.url}${path}`,
      JSON.stringify(body),
      {headers: authHeaders}
    )
      .pipe(catchError( (err) => this.returnError(err)));
  }

  public delete(path: string): Observable<any> {
    const authHeaders = (this.authToken !== '') ? this.headers.append('Authorization', 'Bearer ' + this.authToken) :
      this.headers;
    return this._http.delete(`${environment.url}${path}`, {headers: authHeaders})
      .pipe(catchError( (err) => this.returnError(err) ));
  }

  public setHeaders(headers) {
    console.log('headers' + this.headers.keys() + 'headers');
    Object.keys(headers)
      .forEach((header) => this.headers.set(header, headers[header]));
    console.log('headers' + this.headers.keys() + 'headers');
  }

  public setAuthToken(value) {
    this.authToken = value;
    console.log('set authtoken to: ' + this.authToken);
  }

  private returnError(err) {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
    }

    // ...optionally return a default fallback value so app can continue (pick one)
    // which could be a default value
    // return Observable.of<any>({my: "default value..."});
    // or simply an empty observable
    return empty();

  }
}
