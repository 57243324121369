export type DateType = Date | number | string | undefined | null;

export function makeDate(date: DateType) {
  if (date) {
    if (typeof date === 'string') {
      date = new Date(date).toISOString();
      const parts = date.split('-');
      if (parts) {
        return new Date(
          parseInt(parts[0], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[2], 10)
        );
      }
    } else {
      date = new Date(date);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    }
  }
  return undefined;
}

// For display event/test dates (includes the day of week):
export function dayDate(date: DateType) {
  return (makeDate(date))?.toDateString() || '';
}

// For storing in database (yyyy-mm-dd):
export function isoDate(date: DateType) {
  const newDate = makeDate(date);
  if (newDate) {
    return `${newDate.getFullYear()}-${pad(newDate.getMonth() + 1, 2)}-${pad(newDate.getDate(), 2)}`;
  }
  return '';
}

function pad(value: number, digits: number) {
  let result = value.toString();
  while (result.length < digits) {
    result = '0' + result;
  }
  return result;
}

// For other purposes (m/d/yyyy):
export function shortDate(date: DateType) {
  return (makeDate(date))?.toLocaleDateString() || '';
}
