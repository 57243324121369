import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationData} from '../shared/validationMessages.component';
import {ApiService} from '../services/api.service';
import {CommitteesService} from '../services/committees.service';
import {modes} from '../models/modes.model';
import {NatSpecCategory} from '../models/categories.model';
import {CategoriesService} from '../services/categories.service';

@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.css']
})
export class ManageCategoriesComponent implements OnInit, AfterViewInit {

  categories: NatSpecCategory[];
  mySpinner: boolean;
  mode: number;
  selectedCategory: NatSpecCategory;
  categoryForm: FormGroup;

  validationData: ValidationData = {
    'categoryName': [
      {type: 'required', message: 'Please enter a name for this category'}
    ],
    'categoryDescription': [
      {type: 'required', message: 'Please enter the description for this category'}
    ],
    'categoryProductType': [
      {type: 'required', message: 'Please select the type of this category'}
    ]
  };

  constructor(private api: ApiService,
              private service: CategoriesService,
              private formBuilder: FormBuilder,
              private ngZone: NgZone) {
    this.categoryForm = this.formBuilder.group({
      categoryName: ['', Validators.required],
      categoryDescription: ['', Validators.required],
      categoryIconPath: [''],
      categoryProductType: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.mode = modes.TABLEVIEW;
    this.selectedCategory = null;
  }

  ngAfterViewInit(): void {
    this.refreshCategories();
  }

  refreshCategories() {
    this.mode = modes.TABLEVIEW;
    setTimeout(() => {
      this.mySpinner = true;
      this.service.getCategories().subscribe((res) => {
        this.ngZone.run(() => {
          this.categories = res;
          this.mySpinner = false;
        });
      });
    }, 500);
  }
  addNewCategory() {
    this.selectedCategory = {id: -1, name: '', description: '', icon_path: '', product_type: {id: 1, description: 'Merchandise'}};
    const controls = this.categoryForm.controls;
    const category = this.selectedCategory;
    controls.categoryName.setValue(category.name);
    controls.categoryDescription.setValue(category.description);
    controls.categoryIconPath.setValue(category.icon_path);
    controls.categoryProductType.setValue(category.product_type.id);

    this.mode = modes.EDITVIEW;
  }
  editCategory() {
    const controls = this.categoryForm.controls;
    const category = this.selectedCategory;
    controls.categoryName.setValue(category.name);
    controls.categoryDescription.setValue(category.description);
    controls.categoryIconPath.setValue(category.icon_path);
    controls.categoryProductType.setValue(category.product_type.id);

    this.mode = modes.EDITVIEW;
  }
  saveCategory() {
    this.categoryForm.updateValueAndValidity();
    if (!this.categoryForm.valid) {
      return;
    }
    this.selectedCategory.name = this.categoryForm.controls.categoryName.value;
    this.selectedCategory.description = this.categoryForm.controls.categoryDescription.value;
    this.selectedCategory.icon_path = this.categoryForm.controls.categoryIconPath.value;
    this.selectedCategory.product_type = this.categoryForm.controls.categoryProductType.value;

    if (this.selectedCategory.id === -1) {
      this.service.newCategory(this.selectedCategory)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshCategories();
          });
        });
    } else {
      this.service.updateCategory(this.selectedCategory, this.selectedCategory.id)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.refreshCategories();
          });
        });
    }
  }

  getProductType() {
    switch (this.selectedCategory.product_type) {
      default:
      case 1: return 'Merchandise';
      case 2: return 'Reservations';
      case 3: return 'Signups';
    }
  }
  categoryDetails(index) {
    this.selectedCategory = this.categories[index];
    this.mode = modes.DETAILVIEW;
  }

  isTableViewMode() {
    return this.mode === modes.TABLEVIEW;
  }
  isDetailViewMode() {
    return this.mode === modes.DETAILVIEW;
  }
  isEditViewMode() {
    return this.mode === modes.EDITVIEW;
  }}
