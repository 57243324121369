// Todo: do "returns" and refunds go on an order item or a suborder

import {Product, ProductVariant} from './products.model';

export interface OrderList {
  id?: number;
  name?: string;
  amount?: number;
  orderDate?: Date;
}

export interface OrderItem {
  id?: number;
  quantity?: number;
  product?: Product;
  dataPoints?: any;
  product_variant: ProductVariant;
  parsedDataPoints?: any;
  showDataPoints?: boolean;
}

export interface Committee {
  id?: number;
  name?: string;
  contactName?: string;
  contactEmail?: string;
  waId?: number;
}
export interface SubOrder {
  id?: number;
  committee?: Committee;
  orderitems?: OrderItem[];
  subTotal?: number;
  state?: number;
  shipping?: number;
  trackingNumbers?: string;
}

export interface Customer {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateOrProvince?: string;
  zipOrPostalCode?: string;
  country?: string;
  waId?: number;
}

export interface Shipping {
  id?: number;
  name?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}
export interface Order {
  id?: number;
  orderDate?: Date;
  customer?: Customer;
  shipping?: Shipping;
  ship?: boolean;
  totalCost?: number;
  refundedAmount?: number;
  state?: number;
  suborders?: SubOrder[];
}

export interface UnshippedOrders {
  id?: number;
}
