<div class="row">
  <div class="col-md-12">
    <div *ngIf="isTableViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Complete list of All categories in the system</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="addNewCategory()" type="button">Add Category</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
          <table class="table table-hover table-bordered display pl-2 pr-2 py-0 w-100"
                 style="cursor: pointer;">
            <tr>
              <th width="25%">Name</th>
              <th width="50%">Description</th>
              <th>Icon path</th>
              <th>Product Type</th>
            </tr>
            <tr *ngFor="let aCategory of categories;let index = index;" (click)="categoryDetails(index)">
              <td>{{ aCategory.name }}</td>
              <td>{{ aCategory.description }}</td>
              <td>{{ aCategory.icon_path }}</td>
              <td>{{ aCategory.product_type.description }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="isDetailViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Category Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="editCategory()" type="button">Edit Category</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card w-50 mt-1">
            <div class="card-body">
              <table class="table table-borderless table-sm table-tight ml-2">
                <tr>
                  <td width="25%">Name:</td><td width="75%">{{ selectedCategory.name }}</td>
                </tr>
                <tr>
                  <td>Description:</td><td>{{ selectedCategory.description }}</td>
                </tr>
                <tr>
                  <td>Icon path:</td><td>{{ selectedCategory.icon_path }}</td>
                </tr>
                <tr>
                  <td>Product type:</td><td>{{ selectedCategory.product_type.description }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isEditViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Edit Category Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="saveCategory()" type="button">Save Category</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="card w-50 mt-1">
                <div class="card-body">
                  <form [formGroup]="categoryForm">
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Category Name" formControlName="categoryName">
                      <app-validation-messages [formGroup]="categoryForm" [data]="validationData" name="categoryName"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Description" formControlName="categoryDescription">
                      <app-validation-messages [formGroup]="categoryForm" [data]="validationData" name="categoryDescription"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Icon Path" formControlName="categoryIconPath">
                      <app-validation-messages [formGroup]="categoryForm" [data]="validationData" name="categoryIconPath"></app-validation-messages>
                    </mat-form-field>
                    <mat-form-field style="width: 49%">
                      <select matNativeControl placeholder="Product Type" formControlName="categoryProductType">
                        <option></option>
                        <option value="1">Merchandise</option>
                        <option value="2">Reservations</option>
                        <option value="3">Signups</option>
                      </select>
                      <app-validation-messages [formGroup]="categoryForm" [data]="validationData" name="categoryProductType"></app-validation-messages>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
