import {Component, Input, OnInit} from '@angular/core';
import {NatSpecProduct, Product} from '../models/products.model';

@Component({
  selector: 'app-prod-card',
  templateUrl: './prod-card.component.html',
  styleUrls: ['./prod-card.component.css']
})
export class ProdCardComponent implements OnInit {
  @Input() item: NatSpecProduct;

  constructor() { }

  ngOnInit() {
  }

  getIconToDisplay() {
    if ((this.item.image === null) || (this.item.image === '')) {
      return 'assets/images/product/icon.png';
    } else {
      return 'assets/images/product/' + this.item.image;
    }
  }
}
