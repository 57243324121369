import {Action, createFeatureSelector, createSelector} from '@ngrx/store';
import * as userActions from '../actions/user.actions';
import {User} from '../../models/user.model';

export interface UserState {
  user: User;
  authenticated: boolean;
  token: string;
}

export const initialState: UserState = {
  user: {myId: -1, email: '', first: '', last: '', committeeId: '(0)'},
  authenticated: false,
  token: ''
};

export function reducer(state = initialState, action: userActions.UserActions): UserState {
  switch (action.type) {
    case userActions.UserActionTypes.LoginUser: {
      return Object.assign({}, state, {
        user: action.payload.user,
        authenticated: action.payload.authenticated,
        token: action.payload.token
      });
    }
    case userActions.UserActionTypes.LogoutUser: {
      return Object.assign({}, state, {
        user: {myId: -1, email: '', first: '', last: '', committeeId: '(0)'},
        authenticated: false,
        token: ''
      });
    }
    default:
      return state;
  }
}

export const getUserName = (state: UserState) => state.user.first + ' ' + state.user.last;
export const getUserEmail = (state: UserState) => state.user.email;
export const getUserId = (state: UserState) => state.user.myId;

// export const getUserState = createFeatureSelector<UserState>('userState');

export const getUsers = (state: UserState) => state;
