import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string, currencyColumns: number[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const currency = '"$"#,##0.00_);\\("$"#,##0.00\\)';
    currencyColumns.forEach((colNum) => {
      this.formatColumn(worksheet, colNum, currency);
    });
    let max_width = 0;
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let col = range.s.c; col <= range.e.c; ++col) {
      max_width = 0;
      for (let row = range.s.r; row <= range.e.r; ++row) {
        const ref = XLSX.utils.encode_cell({r: row, c: col});
        if (worksheet[ref]) {
          if (worksheet[ref].t === 's') {
            if (worksheet[ref].v.length > max_width) {
              max_width = worksheet[ref].v.length;
            }
          } else if (worksheet[ref].t === 'n') {
            if (worksheet[ref].v.toString().length > max_width) {
              max_width = worksheet[ref].v.toString().length;
            }
          }
        }
      }
      if (!worksheet['!cols']) {
        worksheet['!cols'] = [];
      }
      if (!worksheet['!cols'][col])  {
        worksheet['!cols'][col] = {wch: max_width};
      }
      worksheet['!cols'][col].wch = max_width;
    }
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
  private formatColumn(worksheet, col, fmt) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    // note: range.s.r + 1 skips the header row
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: col });
      if (worksheet[ref] && worksheet[ref].t === 'n') {
        worksheet[ref].z = fmt;
      }
    }
  }
}
