import {Component, NgZone, OnInit} from '@angular/core';
import {UserState} from '../store/reducers/user.reducer';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../services/api.service';
import {Store} from '@ngrx/store';
import {getCurrentUser} from '../store/reducers';
import { saveAs } from 'file-saver';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-forms-help',
  templateUrl: './forms-help.component.html',
  styleUrls: ['./forms-help.component.css']
})
export class FormsHelpComponent implements OnInit {

  currentUser: UserState = {
    user: { myId: -1, email: '', first: '', last: '' },
    authenticated: false,
    token: ''};

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ngZone: NgZone,
              private http: HttpClient,
              private api: ApiService,
              private store: Store<UserState>) {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
    });
  }

  ngOnInit() {
  }

  exportAll() {
    this.http.get('https://scripts.ncanewfs.org/specialty/FormsExportScript.php?formId=-1&commId=' + this.currentUser.user.committeeId +
      '&toField=' + this.currentUser.user.email + '&token=' + this.api.authToken + '&mode=' + environment.production ? 'true' : 'false',
      {observe: 'response', responseType: 'blob'})
      .subscribe((response) => {
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        const contentTypeHeader: string = response.headers.get('Content-Type');
        const parts: string[] = contentDispositionHeader.split(';');
        const filename = parts[1].split('=')[1].replace(/['"]+/g, '');
        const blob = new Blob([response.body], { type: contentTypeHeader });
        saveAs(blob, filename);

      });
    alert('Your download will begin shortly.  Please wait for the export file to be generated.');
  }

}
