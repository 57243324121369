<div class="row mb-2">
  <div class="col-md-12">
    <button class="btn btn-primary btn-round ml-3" (click)="goback()" type="button">Back to Forms List</button>
  </div>
</div>
<div class="row">
  <div class="col-md-10">
    <h3 class="ml-3">Form details for form #{{ thisForm.id }}</h3>
  </div>
</div>
<div class="row">
  <div class="col-md-10">
    <div class="card w-75 mt-1">
      <div class="card-body">
        <div class="float-left">
          <h5 class="card-title">Form Info</h5>
          <h6 class="card-subtitle mb-2 text-muted">Submit Date: {{ submitReadableDate }}</h6>
        </div>
        <table class="table table-borderless table-sm table-tight ml-2">
          <tr>
            <td width="25%">Committee:</td><td width="75%">{{ thisForm.committeeId }}</td>
          </tr>
          <tr>
            <td>Form Data:</td><td><pre>{{ thisForm.formData | json }}</pre></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
