<nav class="navbar navbar-expand-md navbar-dark bg-transparent">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="logo-container">
    <div class="mx-auto text-center"><img src="assets/NCAtransDE1B1B.png" width="80" height="80">
      <h2 class="d-inline-block align-middle ml-3">{{ pageTitle }}</h2>
    </div>
    <div class="clearfix"></div>
  </div>

  <div class="collapse navbar-collapse flex-grow-1" id="navbarsExampleDefault">
    <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <button (click)="doLogout()"  *ngIf="isLoggedIn" class="nav-link nav-link-header nav-link-wide" href="#">Logout</button>
            </li>
      <!--
       <li class="nav-item active">
        <div *ngIf="isAdmin">
          <button (click)="doLogout()" class="nav-link nav-link-header">Logout</button>
        </div>
      </li>
      -->
    </ul>
  </div>
</nav>

