<div class="row">
  <div class="col-md-12">
    <div *ngIf="isTableViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Complete list of All colors in the system</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="addNewColor()" type="button">Add Color</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
          <table class="table table-hover table-bordered display pl-2 pr-2 py-0 w-100"
                 style="cursor: pointer;">
            <tr>
              <th>Name</th>
            </tr>
            <tr *ngFor="let aColor of colors;let index = index;" (click)="colorDetails(index)">
              <td>{{ aColor.name }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="isDetailViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Color Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="editColor()" type="button">Edit Color</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card w-50 mt-1">
            <div class="card-body">
              <table class="table table-borderless table-sm table-tight ml-2">
                <tr>
                  <td width="25%">Name:</td><td width="75%">{{ selectedColor.name }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isEditViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Edit Color Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="saveColor()" type="button">Save Color</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="card w-50 mt-1">
                <div class="card-body">
                  <form [formGroup]="colorForm">
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Color Name" formControlName="colorName">
                      <app-validation-messages [formGroup]="colorForm" [data]="validationData" name="colorName"></app-validation-messages>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
