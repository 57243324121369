<div class="row">
  <div class="col-md-12">
    <div *ngIf="isTableViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Complete list of All sizes in the system</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="addNewSize()" type="button">Add Size</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
          <table class="table table-hover table-bordered display pl-2 pr-2 py-0 w-100"
                 style="cursor: pointer;">
            <tr>
              <th>Name</th>
            </tr>
            <tr *ngFor="let aSize of sizes;let index = index;" (click)="sizeDetails(index)">
              <td>{{ aSize.name }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="isDetailViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Size Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="editSize()" type="button">Edit Size</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card w-50 mt-1">
            <div class="card-body">
              <table class="table table-borderless table-sm table-tight ml-2">
                <tr>
                  <td width="25%">Name:</td><td width="75%">{{ selectedSize.name }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isEditViewMode()">
      <div class="row mb-2">
        <div class="col-md-8">
          <h2>Edit Size Details</h2>
        </div>
        <div class="col-md-4 ml-auto">
          <button class="btn btn-primary btn-round ml-3" (click)="saveSize()" type="button">Save Size</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="card w-50 mt-1">
                <div class="card-body">
                  <form [formGroup]="sizeForm">
                    <mat-form-field style="width: 79%; margin-right: 1%">
                      <input matInput maxlength=100 placeholder="Size Name" formControlName="sizeName">
                      <app-validation-messages [formGroup]="sizeForm" [data]="validationData" name="sizeName"></app-validation-messages>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
