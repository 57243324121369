import {Component, NgZone, OnInit} from '@angular/core';
import {OrderList} from '../models/orders.model';
import {Router} from '@angular/router';
import {OrdersService} from '../services/orders.service';
import {select, Store} from '@ngrx/store';
import {getCurrentUser} from '../store/reducers';
import {getUsers, UserState} from '../store/reducers/user.reducer';
import * as moment from 'moment';

@Component({
  selector: 'app-suborders',
  templateUrl: './suborders.component.html',
  styleUrls: ['./suborders.component.css']
})
export class SubordersComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  orders: OrderList[];
  noData = true;
  currentUser: UserState = {
      user: {myId: -1, email: '', first: '', last: '' },
      authenticated: false,
      token: ''};

  public pageTitle = 'Suborder Management';

  constructor(private router: Router,
              private service: OrdersService,
              private store: Store<UserState>,
              private ngZone: NgZone) { }

  ngOnInit() {
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.currentUser = state;
      if (!this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        serverSide: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.ngZone.run(() => {
            this.service.getSubOrdersTable(
              dataTablesParameters, this.currentUser.user.committeeId)
              .subscribe(resp => {
                if (resp.hasOwnProperty('orderList')) {
                  this.orders = resp.orderList;
                  if (this.orders.length > 0) {
                    this.noData = false;
                  } else {
                    this.noData = true;
                  }
                } else {
                  resp['orderList'] = [];
                }

                callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.orderList
                });
              });
          });
        },
        order: [[1, 'desc']],
        columns: [{
          title: 'ID',
          data: 'id'
        }, {
          title: 'Order Date',
          data: 'orderDate'
        }, {
          title: 'Customer',
          data: 'name'
        }, {
          title: 'Amount',
          data: 'amount',
          className: 'text-right',
          render: $.fn.dataTable.render.number( ',', '.', 2, '$ ', '' )
        }, {
          title: 'State',
          data: 'state'
        }],
        rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          // Unbind first in order to avoid any duplicate handler
          // (see https://github.com/l-lin/angular-datatables/issues/87)
          $('td', row).unbind('click');
          $('td', row).bind('click', () => {
            self.someClickHandler(data);
          });
          const ordernumeric = data['orderDate'];
          const orderDate = moment(ordernumeric);
          $('td:eq(1)', row).text(orderDate.format('LLL'));
          return row;
        }
      };
    });
  }

  someClickHandler(info: any): void {
    /*
    this.selectedDonor = {identity: info.identity};
    const dState: DonorState = {donor: this.selectedDonor, enhanced: info};
    this.store.dispatch(new donorActions.SetSelectedDonorAction(dState));
    this.router.navigateByUrl('/app/donor-details');
    */
    const orderId = info.id;
    this.router.navigateByUrl('suborder-detail/' + orderId);
  }

  logout() {
    this.router.navigateByUrl('');
  }
}
