import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NatSpecCommittee} from '../models/committee.model';

@Injectable()
export class CommitteesService {
  constructor(private _api: ApiService,
              private _http: HttpClient) {
  }

  public getCommittees(): Observable<any> {
    return this._api.get('/committees');
  }
  public updateCommittee(body: NatSpecCommittee, committeeId: number): Observable<any> {
    return this._api.put('/committees/' + committeeId, body);
  }
  public newCommittee(body: any): Observable<any> {
    return this._api.post('/committees', body);
  }

}
