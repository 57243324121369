<table class="table table-bordered table-pack">
  <thead>
  <tr>
    <th>Item #</th>
    <th>Description</th>
    <th>Quantity</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of workingSub.orderitems; let index = index;">
    <td>{{ index + 1 }}</td>
    <td>{{ getDisplayName(item) }}</td>
    <td>{{ item.quantity }}</td>
  </tr>
  </tbody>
</table>
