<div class="row mt-4 ml-4">
  <div class="col-md-12">
    <div>
      <form [formGroup]="smsForm" #lf="ngForm">
        <div class="row">
          <div class="col-md-12">
            <h5>Please select which group of subscribers will receive this message by selecting the appropriate topic name.</h5>
            <p class="mb-0">Use <span class="font-weight-bold">&quot;National Alerts&quot;</span> for messages to all National subscribers</p>
            <p class="mb-3">Use <span class="font-weight-bold">&quot;Juniors Alerts&quot;</span> for messages to all Juniors subscribers</p>
            <mat-form-field class="inputField-half-width">
              <mat-label>Select a Topic</mat-label>
              <mat-select formControlName="topic">
                <mat-option value="nationalAlerts">National Alerts</mat-option>
                <mat-option value="juniorsAlerts">Juniors Alerts</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field style="width: 79%; margin-right: 1%">
              <textarea
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                placeholder="Message Body"
                formControlName="messageBody"></textarea>
              <app-validation-messages [formGroup]="smsForm" [data]="validationData" [name]="'messageBody'"></app-validation-messages>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
              <mat-radio-group name="sendAt" class="example-radio-group" (change)="changeRadio($event)" formControlName="sendAt">
                <mat-radio-button class="example-radio-button" value="now" checked>Immediately</mat-radio-button>
                <mat-radio-button class="example-radio-button" value="atTime">Later</mat-radio-button>
              </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <mat-form-field>
              <input id="later" matInput [ngxMatDatetimePicker]="picker" placeholder="Choose when to send this message"
                 formControlName="dateControl">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [enableMeridian]="enableMeridian">
                <ng-template>
                  <!-- <mat-icon>star</mat-icon> -->
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <p class="text-danger">{{ errorMessage }}</p>
          </div>
        </div>
        <div class="position-relative text-right">
          <div class="row">
            <div class="col-md-4">
              <button (click)="doSend()" class="btn btn-lg btn-info btn-block" type="button" id="messageSend">Send</button>
              <div *ngIf="sending" class="logging-spinner"></div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
