<div class="container-fluid">
  <div class="row bg-light">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="" (click)="goHome()">Product Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Product Category Detail</li>
      </ol>
    </nav>
  </div>
  <div class="row bg-light">
    <div class="col-md-12 text-right">
      <button class="btn btn-info">Add Product</button>
    </div>
  </div>
  <div class="row bg-light">
    <div class="col-sm-10 mx-auto d-block">
      <p><span class="prod-list-header">Products in this category: {{ categoryName }}</span>
      </p>
      <!-- <pre>{{ masterList | json}}</pre> -->
      <div class="grid">
        <div *ngIf="mySpinner"><mat-spinner class="mx-auto"></mat-spinner></div>
        <div class="prod-cat-card" *ngFor="let product of products" (click)="gotoDetails(product)">
          <app-prod-card [item]="product"></app-prod-card>
        </div>
      </div>
    </div>
  </div>
</div>
