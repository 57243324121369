import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as userActions from '../store/actions/user.actions';
import {Store} from '@ngrx/store';
import {UserState} from '../store/reducers/user.reducer';
import {ApiService} from '../services/api.service';
import {getCurrentUser} from '../store/reducers';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  notOnHome: boolean;
  isAdmin: boolean;
  pageTitle: string;
  isLoggedIn: boolean;

  constructor(private router: Router,
              private api: ApiService,
              private ngZone: NgZone,
              private store: Store<UserState>) {
    this.notOnHome = true;
    this.isLoggedIn = false;
    this.pageTitle = 'National Specialty Administration';
  }

  ngOnInit() {
/*
    this.store.select(getCurrentUser).subscribe((state: UserState) => {
      this.ngZone.run(() => {
        this.isLoggedIn = state.authenticated;
        if (!this.isLoggedIn) {
          // we don't have a logintoken so check the cookie
          let cookieVal = '';
          const knumDays = 1;
          const value = '; ' + document.cookie;
          const parts = value.split('; natspecadmintoken=');
          if (parts.length === 2) {
            // found a token in a cookie, try using it
            cookieVal = parts.pop().split(';').shift();
            console.log('header constructor found a cookie with a token');
            this.api.setAuthToken(cookieVal);
            this.isLoggedIn = true;
            const loginGuy: UserState = {user: state.user,
              authenticated: true, token: cookieVal};
            this.store.dispatch( new userActions.LoginUserAction(loginGuy));
          }
        }
      });
    });
*/
  }

  gohome() {
    this.router.navigateByUrl('/');
  }

  doLogout() {
    const value = '; ' + document.cookie;
    const parts = value.split('; natspecadmintoken=');
    if (parts.length === 2) {
      // found a token in a cookie, try using it
      const cookieVal = parts.pop().split(';').shift();
      const date = new Date();
      const knumDays = 1;
      date.setTime(date.getTime() - (knumDays * 24 * 60 * 60 * 1000));
      const expires = '; expires=' + date.toUTCString();
      document.cookie = 'natspecadmintoken=' + cookieVal + expires + '; path=/';
    }
    this.isLoggedIn = false;
    this.api.setAuthToken('');
    this.store.dispatch( new userActions.LogoutUserAction());
    this.router.navigateByUrl('/');
  }

}
